export const AllLocations = [
    {
        "name": {
            "zh_CN": "亚洲",
            "zh_TW": "亞洲",
            "en_US": "Asia"
        },
        "countries": [
            {
                "name": {
                    "zh_CN": "阿富汗",
                    "zh_TW": "阿富汗",
                    "en_US": "Afghanistan"
                },
                "code": "AF",
                "ips": "260",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "亚美尼亚",
                    "zh_TW": "亞美尼亞",
                    "en_US": "Armenia"
                },
                "code": "AM",
                "ips": "29920",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "阿塞拜疆",
                    "zh_TW": "阿塞拜疆",
                    "en_US": "Azerbaijan"
                },
                "code": "AZ",
                "ips": "46200",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴林",
                    "zh_TW": "巴林",
                    "en_US": "Bahrain"
                },
                "code": "BH",
                "ips": "199800",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "孟加拉国",
                    "zh_TW": "孟加拉國",
                    "en_US": "Bangladesh"
                },
                "code": "BD",
                "ips": "330240",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "不丹",
                    "zh_TW": "不丹",
                    "en_US": "Bhutan"
                },
                "code": "BT",
                "ips": "451",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "文莱",
                    "zh_TW": "文萊",
                    "en_US": "Brunei"
                },
                "code": "BN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "柬埔寨",
                    "zh_TW": "柬埔寨",
                    "en_US": "Cambodia"
                },
                "code": "KH",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "中国",
                    "zh_TW": "中國",
                    "en_US": "China"
                },
                "code": "CN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "格鲁吉亚",
                    "zh_TW": "格魯吉亞",
                    "en_US": "Georgia"
                },
                "code": "GE",
                "ips": "34660",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "中国-香港",
                    "zh_TW": "中國-香港",
                    "en_US": "Hong Kong,China"
                },
                "code": "HK",
                "ips": "34760",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "印度",
                    "zh_TW": "印度",
                    "en_US": "India"
                },
                "code": "IN",
                "ips": "67668",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "印度尼西亚",
                    "zh_TW": "印度尼西亞",
                    "en_US": "Indonesia"
                },
                "code": "ID",
                "ips": "908800",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "伊朗",
                    "zh_TW": "伊朗",
                    "en_US": "Iran"
                },
                "code": "IR",
                "ips": "12100",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "伊拉克",
                    "zh_TW": "伊拉克",
                    "en_US": "Iraq"
                },
                "code": "IQ",
                "ips": "269080",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "以色列",
                    "zh_TW": "以色列",
                    "en_US": "Israel"
                },
                "code": "IL",
                "ips": "176560",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "日本",
                    "zh_TW": "日本",
                    "en_US": "Japan"
                },
                "code": "JP",
                "ips": "2570",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "约旦",
                    "zh_TW": "約旦",
                    "en_US": "Jordan"
                },
                "code": "JO",
                "ips": "45380",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "哈萨克斯坦",
                    "zh_TW": "哈薩克斯坦",
                    "en_US": "Kazakhstan"
                },
                "code": "KZ",
                "ips": "97400",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "科威特",
                    "zh_TW": "科威特",
                    "en_US": "Kuwait"
                },
                "code": "KW",
                "ips": "74060",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "吉尔吉斯斯坦",
                    "zh_TW": "吉爾吉斯斯坦",
                    "en_US": "Kyrgyzstan"
                },
                "code": "KG",
                "ips": "16700",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "老挝",
                    "zh_TW": "老撾",
                    "en_US": "Laos"
                },
                "code": "LA",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "黎巴嫩",
                    "zh_TW": "黎巴嫩",
                    "en_US": "Lebanon"
                },
                "code": "LB",
                "ips": "68040",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马来西亚",
                    "zh_TW": "馬來西亞",
                    "en_US": "Malaysia"
                },
                "code": "MY",
                "ips": "1520080",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马尔代夫",
                    "zh_TW": "馬爾代夫",
                    "en_US": "Maldives"
                },
                "code": "MV",
                "ips": "1240",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "蒙古",
                    "zh_TW": "蒙古",
                    "en_US": "Mongolia"
                },
                "code": "MN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "缅甸",
                    "zh_TW": "緬甸",
                    "en_US": "Myanmar"
                },
                "code": "MM",
                "ips": "109040",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "尼泊尔",
                    "zh_TW": "尼泊爾",
                    "en_US": "Nepal"
                },
                "code": "NP",
                "ips": "252000",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "阿曼",
                    "zh_TW": "阿曼",
                    "en_US": "Oman"
                },
                "code": "OM",
                "ips": "49520",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴基斯坦",
                    "zh_TW": "巴基斯坦",
                    "en_US": "Pakistan"
                },
                "code": "PK",
                "ips": "252940",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴勒斯坦",
                    "zh_TW": "巴勒斯坦",
                    "en_US": "Palestine"
                },
                "code": "PS",
                "ips": "65540",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "菲律宾",
                    "zh_TW": "菲律賓",
                    "en_US": "Philippines"
                },
                "code": "PH",
                "ips": "1616220",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "卡塔尔",
                    "zh_TW": "卡塔爾",
                    "en_US": "Qatar"
                },
                "code": "QA",
                "ips": "20060",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "沙特阿拉伯",
                    "zh_TW": "沙特阿拉伯",
                    "en_US": "Saudi Arabia"
                },
                "code": "SA",
                "ips": "395120",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "韩国",
                    "zh_TW": "韓國",
                    "en_US": "South Korea"
                },
                "code": "KR",
                "ips": "5511",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "斯里兰卡",
                    "zh_TW": "斯里蘭卡",
                    "en_US": "Sri Lanka"
                },
                "code": "LK",
                "ips": "3060",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "叙利亚",
                    "zh_TW": "敘利亞",
                    "en_US": "Syria"
                },
                "code": "SY",
                "ips": "20",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "中国-台湾",
                    "zh_TW": "中國-臺灣",
                    "en_US": "Taiwan,China"
                },
                "code": "TW",
                "ips": "117580",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "新加坡",
                    "zh_TW": "新加坡",
                    "en_US": "Singapore"
                },
                "code": "SG",
                "ips": "2580",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "塔吉克斯坦",
                    "zh_TW": "塔吉克斯坦",
                    "en_US": "Tajikistan"
                },
                "code": "TJ",
                "ips": "1160",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "泰国",
                    "zh_TW": "泰國",
                    "en_US": "Thailand"
                },
                "code": "TH",
                "ips": "223960",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "土耳其",
                    "zh_TW": "土耳其",
                    "en_US": "Turkey"
                },
                "code": "TR",
                "ips": "1371440",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "阿联酋",
                    "zh_TW": "阿聯酋",
                    "en_US": "UAE"
                },
                "code": "AE",
                "ips": "258760",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "乌兹别克斯坦",
                    "zh_TW": "烏茲別克斯坦",
                    "en_US": "Uzbekistan"
                },
                "code": "UZ",
                "ips": "234540",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "越南",
                    "zh_TW": "越南",
                    "en_US": "Vietnam"
                },
                "code": "VN",
                "ips": "520960",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "也门",
                    "zh_TW": "也門",
                    "en_US": "Yemen"
                },
                "code": "YE",
                "ips": "522",
                "cities": []
            }
        ]
    },
    {
        "name": {
            "zh_CN": "欧洲",
            "zh_TW": "歐洲",
            "en_US": "Europe"
        },
        "countries": [
            {
                "name": {
                    "zh_CN": "阿尔巴尼亚",
                    "zh_TW": "阿爾巴尼亞",
                    "en_US": "Albania"
                },
                "code": "AL",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "安道尔",
                    "zh_TW": "安道爾",
                    "en_US": "Andorra"
                },
                "code": "AD",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "奥地利",
                    "zh_TW": "奧地利",
                    "en_US": "Austria"
                },
                "code": "AT",
                "ips": "17800",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "白俄罗斯",
                    "zh_TW": "白俄羅斯",
                    "en_US": "Belarus"
                },
                "code": "BY",
                "ips": "66960",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "比利时",
                    "zh_TW": "比利時",
                    "en_US": "Belgium"
                },
                "code": "BE",
                "ips": "60340",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "波黑",
                    "zh_TW": "波赫",
                    "en_US": "BiH"
                },
                "code": "BA",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "保加利亚",
                    "zh_TW": "保加利亞",
                    "en_US": "Bulgaria"
                },
                "code": "BG",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "克罗地亚",
                    "zh_TW": "克羅地亞",
                    "en_US": "Croatia"
                },
                "code": "HR",
                "ips": "18000",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "塞浦路斯",
                    "zh_TW": "塞浦路斯",
                    "en_US": "Cyprus"
                },
                "code": "CY",
                "ips": "28420",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "捷克共和国",
                    "zh_TW": "捷克共和國",
                    "en_US": "Czech Republic"
                },
                "code": "CZ",
                "ips": "44880",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "丹麦",
                    "zh_TW": "丹麥",
                    "en_US": "Denmark"
                },
                "code": "DK",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "爱沙尼亚",
                    "zh_TW": "愛沙尼亞",
                    "en_US": "Estonia"
                },
                "code": "EE",
                "ips": "12200",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "芬兰",
                    "zh_TW": "芬蘭",
                    "en_US": "Finland"
                },
                "code": "FI",
                "ips": "4060",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "法国",
                    "zh_TW": "法國",
                    "en_US": "France"
                },
                "code": "FR",
                "ips": "905340",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "德国",
                    "zh_TW": "德國",
                    "en_US": "Germany"
                },
                "code": "DE",
                "ips": "7822",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "希腊",
                    "zh_TW": "希臘",
                    "en_US": "Greece"
                },
                "code": "GR",
                "ips": "163820",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "匈牙利",
                    "zh_TW": "匈牙利",
                    "en_US": "Hungary"
                },
                "code": "HU",
                "ips": "21720",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "冰岛",
                    "zh_TW": "冰島",
                    "en_US": "Iceland"
                },
                "code": "IS",
                "ips": "1280",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "爱尔兰",
                    "zh_TW": "愛爾蘭",
                    "en_US": "Ireland"
                },
                "code": "IE",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "意大利",
                    "zh_TW": "意大利",
                    "en_US": "Italy"
                },
                "code": "IT",
                "ips": "442120",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "科索沃",
                    "zh_TW": "科索沃",
                    "en_US": "Kosovo"
                },
                "code": "XK",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "拉脱维亚",
                    "zh_TW": "拉脫維亞",
                    "en_US": "Latvia"
                },
                "code": "LV",
                "ips": "22800",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "立陶宛",
                    "zh_TW": "立陶宛",
                    "en_US": "Lithuania"
                },
                "code": "LT",
                "ips": "19560",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "卢森堡",
                    "zh_TW": "盧森堡",
                    "en_US": "Luxembourg"
                },
                "code": "LU",
                "ips": "7740",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马耳他",
                    "zh_TW": "馬耳他",
                    "en_US": "Malta"
                },
                "code": "MT",
                "ips": "12680",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "摩尔多瓦",
                    "zh_TW": "摩爾多瓦",
                    "en_US": "Moldova"
                },
                "code": "MD",
                "ips": "50980",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "黑山",
                    "zh_TW": "黑山",
                    "en_US": "Montenegro"
                },
                "code": "ME",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "荷兰",
                    "zh_TW": "荷蘭",
                    "en_US": "Netherlands"
                },
                "code": "NL",
                "ips": "68760",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "北马其顿",
                    "zh_TW": "北馬其頓",
                    "en_US": "North Macedonia"
                },
                "code": "MK",
                "ips": "7109",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "挪威",
                    "zh_TW": "挪威",
                    "en_US": "Norway"
                },
                "code": "NO",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "波兰",
                    "zh_TW": "波蘭",
                    "en_US": "Poland"
                },
                "code": "PL",
                "ips": "52280",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "葡萄牙",
                    "zh_TW": "葡萄牙",
                    "en_US": "Portugal"
                },
                "code": "PT",
                "ips": "134420",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "罗马尼亚",
                    "zh_TW": "羅馬尼亞",
                    "en_US": "Romania"
                },
                "code": "RO",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "俄罗斯",
                    "zh_TW": "俄羅斯",
                    "en_US": "Russia"
                },
                "code": "RU",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "塞尔维亚",
                    "zh_TW": "塞爾維亞",
                    "en_US": "Serbia"
                },
                "code": "RS",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "斯洛伐克",
                    "zh_TW": "斯洛伐克",
                    "en_US": "Slovakia"
                },
                "code": "SK",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "斯洛文尼亚",
                    "zh_TW": "斯洛文尼亞",
                    "en_US": "Slovenia"
                },
                "code": "SI",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "西班牙",
                    "zh_TW": "西班牙",
                    "en_US": "Spain"
                },
                "code": "ES",
                "ips": "681780",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "瑞典",
                    "zh_TW": "瑞典",
                    "en_US": "Sweden"
                },
                "code": "SE",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "瑞士",
                    "zh_TW": "瑞士",
                    "en_US": "Switzerland"
                },
                "code": "CH",
                "ips": "60574",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "乌克兰",
                    "zh_TW": "烏克蘭",
                    "en_US": "Ukraine"
                },
                "code": "UA",
                "ips": "378400",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "英国",
                    "zh_TW": "英國",
                    "en_US": "United Kingdom"
                },
                "code": "GB",
                "ips": "13653",
                "cities": []
            }
        ]
    },
    {
        "name": {
            "zh_CN": "北美洲",
            "zh_TW": "北美洲",
            "en_US": "North America"
        },
        "countries": [
            {
                "name": {
                    "zh_CN": "安圭拉",
                    "zh_TW": "安圭拉",
                    "en_US": "Anguilla"
                },
                "code": "AI",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "安提瓜和巴布达",
                    "zh_TW": "安提瓜和巴布達",
                    "en_US": "Antigua and Barbuda"
                },
                "code": "AG",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴哈马",
                    "zh_TW": "巴哈馬",
                    "en_US": "Bahamas"
                },
                "code": "BS",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴巴多斯",
                    "zh_TW": "巴巴多斯",
                    "en_US": "Barbados"
                },
                "code": "BB",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "伯利兹",
                    "zh_TW": "伯利茲",
                    "en_US": "Belize"
                },
                "code": "BZ",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "百慕大",
                    "zh_TW": "百慕大",
                    "en_US": "Bermuda"
                },
                "code": "BM",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "加拿大",
                    "zh_TW": "加拿大",
                    "en_US": "Canada"
                },
                "code": "CA",
                "ips": "1231080",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "开曼群岛",
                    "zh_TW": "開曼羣島",
                    "en_US": "Cayman Islands"
                },
                "code": "KY",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "哥斯达黎加",
                    "zh_TW": "哥斯達黎加",
                    "en_US": "Costa Rica"
                },
                "code": "CR",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "古巴",
                    "zh_TW": "古巴",
                    "en_US": "Cuba"
                },
                "code": "CU",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "多米尼加共和国",
                    "zh_TW": "多米尼加共和國",
                    "en_US": "Dominican Rep."
                },
                "code": "DO",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "救星",
                    "zh_TW": "救星",
                    "en_US": "El Salvador"
                },
                "code": "SV",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "格林纳达",
                    "zh_TW": "格林納達",
                    "en_US": "Grenada"
                },
                "code": "GD",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "瓜德罗普岛",
                    "zh_TW": "瓜德羅普島",
                    "en_US": "Guadeloupe"
                },
                "code": "GP",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "危地马拉",
                    "zh_TW": "危地馬拉",
                    "en_US": "Guatemala"
                },
                "code": "GT",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "海地",
                    "zh_TW": "海地",
                    "en_US": "Haiti"
                },
                "code": "HT",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "洪都拉斯",
                    "zh_TW": "洪都拉斯",
                    "en_US": "Honduras"
                },
                "code": "HN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "牙买加",
                    "zh_TW": "牙買加",
                    "en_US": "Jamaica"
                },
                "code": "JM",
                "ips": "34240",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马提尼克岛",
                    "zh_TW": "馬提尼克島",
                    "en_US": "Martinique"
                },
                "code": "MQ",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "墨西哥",
                    "zh_TW": "墨西哥",
                    "en_US": "Mexico"
                },
                "code": "MX",
                "ips": "2138920",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "尼加拉瓜",
                    "zh_TW": "尼加拉瓜",
                    "en_US": "Nicaragua"
                },
                "code": "NI",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴拿马",
                    "zh_TW": "巴拿馬",
                    "en_US": "Panama"
                },
                "code": "PA",
                "ips": "185280",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "波多黎各",
                    "zh_TW": "波多黎各",
                    "en_US": "Puerto Rico"
                },
                "code": "PR",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "圣基茨和尼维斯",
                    "zh_TW": "聖基茨和尼維斯",
                    "en_US": "Saint Kitts and Nevis"
                },
                "code": "KN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "圣卢西亚",
                    "zh_TW": "聖盧西亞",
                    "en_US": "Saint Lucia"
                },
                "code": "LC",
                "ips": "1440",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "圣文森特",
                    "zh_TW": "聖文森特",
                    "en_US": "St. Vincent"
                },
                "code": "VC",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "特立尼达和多巴哥",
                    "zh_TW": "特立尼達和多巴哥",
                    "en_US": "Trinidad and Tobago"
                },
                "code": "TT",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "美国",
                    "zh_TW": "美國",
                    "en_US": "United States"
                },
                "code": "US",
                "ips": "110517",
                "cities": []
            }
        ]
    },
    {
        "name": {
            "zh_CN": "南美洲",
            "zh_TW": "南美洲",
            "en_US": "South America"
        },
        "countries": [
            {
                "name": {
                    "zh_CN": "阿根廷",
                    "zh_TW": "阿根廷",
                    "en_US": "Argentina"
                },
                "code": "AR",
                "ips": "1034820",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "玻利维亚",
                    "zh_TW": "玻利維亞",
                    "en_US": "Bolivia"
                },
                "code": "BO",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴西",
                    "zh_TW": "巴西",
                    "en_US": "Brazil"
                },
                "code": "BR",
                "ips": "47268060",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "智利",
                    "zh_TW": "智利",
                    "en_US": "Chile"
                },
                "code": "CL",
                "ips": "162860",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "哥伦比亚",
                    "zh_TW": "哥倫比亞",
                    "en_US": "Colombia"
                },
                "code": "CO",
                "ips": "528740",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "厄瓜多尔",
                    "zh_TW": "厄瓜多爾",
                    "en_US": "Ecuador"
                },
                "code": "EC",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "法属圭亚那",
                    "zh_TW": "法屬圭亞那",
                    "en_US": "French Guiana"
                },
                "code": "GF",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "圭亚那",
                    "zh_TW": "圭亞那",
                    "en_US": "Guyana"
                },
                "code": "GY",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "巴拉圭",
                    "zh_TW": "巴拉圭",
                    "en_US": "Paraguay"
                },
                "code": "PY",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "秘鲁",
                    "zh_TW": "祕魯",
                    "en_US": "Peru"
                },
                "code": "PE",
                "ips": "269440",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "苏里南",
                    "zh_TW": "蘇里南",
                    "en_US": "Suriname"
                },
                "code": "SR",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "乌拉圭",
                    "zh_TW": "烏拉圭",
                    "en_US": "Uruguay"
                },
                "code": "UY",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "委内瑞拉",
                    "zh_TW": "委內瑞拉",
                    "en_US": "Venezuela"
                },
                "code": "VE",
                "ips": "243500",
                "cities": []
            }
        ]
    },
    {
        "name": {
            "zh_CN": "大洋洲",
            "zh_TW": "大洋洲",
            "en_US": "Oceania"
        },
        "countries": [
            {
                "name": {
                    "zh_CN": "澳大利亚",
                    "zh_TW": "澳大利亞",
                    "en_US": "Australia"
                },
                "code": "AU",
                "ips": "101440",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "斐济",
                    "zh_TW": "斐濟",
                    "en_US": "Fiji"
                },
                "code": "FJ",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "关岛",
                    "zh_TW": "關島",
                    "en_US": "Guam"
                },
                "code": "GU",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "新喀里多尼亚",
                    "zh_TW": "新喀里多尼亞",
                    "en_US": "New Caledonia"
                },
                "code": "NC",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "新西兰",
                    "zh_TW": "新西蘭",
                    "en_US": "New Zealand"
                },
                "code": "NZ",
                "ips": "22220",
                "cities": []
            }
        ]
    },
    {
        "name": {
            "zh_CN": "非洲",
            "zh_TW": "非洲",
            "en_US": "Africa"
        },
        "countries": [
            {
                "name": {
                    "zh_CN": "阿尔及利亚",
                    "zh_TW": "阿爾及利亞",
                    "en_US": "Algeria"
                },
                "code": "DZ",
                "ips": "94320",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "安哥拉",
                    "zh_TW": "安哥拉",
                    "en_US": "Angola"
                },
                "code": "AO",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "贝宁",
                    "zh_TW": "貝寧",
                    "en_US": "Benin"
                },
                "code": "BJ",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "博茨瓦纳",
                    "zh_TW": "博茨瓦納",
                    "en_US": "Botswana"
                },
                "code": "BW",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "布基纳法索",
                    "zh_TW": "布基納法索",
                    "en_US": "Burkina Faso"
                },
                "code": "BF",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "喀麦隆",
                    "zh_TW": "喀麥隆",
                    "en_US": "Cameroon"
                },
                "code": "CM",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "佛得角",
                    "zh_TW": "佛得角",
                    "en_US": "Cape Verde"
                },
                "code": "CV",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "刚果共和国",
                    "zh_TW": "剛果共和國",
                    "en_US": "Congo Republic"
                },
                "code": "CG",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "刚果民主共和国",
                    "zh_TW": "剛果民主共和國",
                    "en_US": "DR Congo"
                },
                "code": "CD",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "吉布提",
                    "zh_TW": "吉布提",
                    "en_US": "Djibouti"
                },
                "code": "DJ",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "埃及",
                    "zh_TW": "埃及",
                    "en_US": "Egypt"
                },
                "code": "EG",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "埃塞俄比亚",
                    "zh_TW": "埃塞俄比亞",
                    "en_US": "Ethiopia"
                },
                "code": "ET",
                "ips": "1382",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "加蓬",
                    "zh_TW": "加蓬",
                    "en_US": "Gabon"
                },
                "code": "GA",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "冈比亚",
                    "zh_TW": "岡比亞",
                    "en_US": "Gambia"
                },
                "code": "GM",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "加纳",
                    "zh_TW": "加納",
                    "en_US": "Ghana"
                },
                "code": "GH",
                "ips": "1640",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "几内亚",
                    "zh_TW": "幾內亞",
                    "en_US": "Guinea"
                },
                "code": "GN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "科特迪瓦",
                    "zh_TW": "科特迪瓦",
                    "en_US": "Ivory Coast"
                },
                "code": "CI",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "肯尼亚",
                    "zh_TW": "肯尼亞",
                    "en_US": "Kenya"
                },
                "code": "KE",
                "ips": "91580",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "利比亚",
                    "zh_TW": "利比亞",
                    "en_US": "Libya"
                },
                "code": "LY",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马达加斯加",
                    "zh_TW": "馬達加斯加",
                    "en_US": "Madagascar"
                },
                "code": "MG",
                "ips": "1480",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马拉维",
                    "zh_TW": "馬拉維",
                    "en_US": "Malawi"
                },
                "code": "MW",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马里",
                    "zh_TW": "馬利",
                    "en_US": "Mali"
                },
                "code": "ML",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "毛里求斯",
                    "zh_TW": "毛里求斯",
                    "en_US": "Mauritius"
                },
                "code": "MU",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "马约特岛",
                    "zh_TW": "馬約特島",
                    "en_US": "Mayotte"
                },
                "code": "YT",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "摩洛哥",
                    "zh_TW": "摩洛哥",
                    "en_US": "Morocco"
                },
                "code": "MA",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "莫桑比克",
                    "zh_TW": "莫桑比克",
                    "en_US": "Mozambique"
                },
                "code": "MZ",
                "ips": "3160",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "纳米比亚",
                    "zh_TW": "納米比亞",
                    "en_US": "Namibia"
                },
                "code": "NA",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "尼日利亚",
                    "zh_TW": "尼日利亞",
                    "en_US": "Nigeria"
                },
                "code": "NG",
                "ips": "8600",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "留尼汪",
                    "zh_TW": "留尼汪",
                    "en_US": "Reunion"
                },
                "code": "RE",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "卢旺达",
                    "zh_TW": "盧旺達",
                    "en_US": "Rwanda"
                },
                "code": "RW",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "圣多美和普林西比",
                    "zh_TW": "聖多美和普林西比",
                    "en_US": "Sao Tome and Principe"
                },
                "code": "ST",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "塞内加尔",
                    "zh_TW": "塞內加爾",
                    "en_US": "Senegal"
                },
                "code": "SN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "塞舌尔",
                    "zh_TW": "塞舌爾",
                    "en_US": "Seychelles"
                },
                "code": "SC",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "索马里",
                    "zh_TW": "索馬里",
                    "en_US": "Somalia"
                },
                "code": "SO",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "南非",
                    "zh_TW": "南非",
                    "en_US": "South Africa"
                },
                "code": "ZA",
                "ips": "700200",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "苏丹",
                    "zh_TW": "蘇丹",
                    "en_US": "Sudan"
                },
                "code": "SD",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "坦桑尼亚",
                    "zh_TW": "坦桑尼亞",
                    "en_US": "Tanzania"
                },
                "code": "TZ",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "多哥",
                    "zh_TW": "多哥",
                    "en_US": "Togo"
                },
                "code": "TG",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "突尼斯",
                    "zh_TW": "突尼斯",
                    "en_US": "Tunisia"
                },
                "code": "TN",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "乌干达",
                    "zh_TW": "烏干達",
                    "en_US": "Uganda"
                },
                "code": "UG",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "赞比亚",
                    "zh_TW": "贊比亞",
                    "en_US": "Zambia"
                },
                "code": "ZM",
                "ips": "0",
                "cities": []
            },
            {
                "name": {
                    "zh_CN": "津巴布韦",
                    "zh_TW": "津巴布韋",
                    "en_US": "Zimbabwe"
                },
                "code": "ZW",
                "ips": "0",
                "cities": []
            }
        ]
    }
]

export const CountriesList = {
    "PH": {
        "name": {
            "zh_CN": "菲律宾",
            "zh_TW": "菲律賓",
            "en_US": "Philippines"
        },
        "code": "PH",
        "ips": "1616220",
        "cities": []
    },
    "MY": {
        "name": {
            "zh_CN": "马来西亚",
            "zh_TW": "馬來西亞",
            "en_US": "Malaysia"
        },
        "code": "MY",
        "ips": "1520080",
        "cities": []
    },
    "ID": {
        "name": {
            "zh_CN": "印度尼西亚",
            "zh_TW": "印度尼西亞",
            "en_US": "Indonesia"
        },
        "code": "ID",
        "ips": "908800",
        "cities": []
    },
    "VN": {
        "name": {
            "zh_CN": "越南",
            "zh_TW": "越南",
            "en_US": "Vietnam"
        },
        "code": "VN",
        "ips": "520960",
        "cities": []
    },
    "IN": {
        "name": {
            "zh_CN": "印度",
            "zh_TW": "印度",
            "en_US": "India"
        },
        "code": "IN",
        "ips": "67668",
        "cities": []
    },
    "SA": {
        "name": {
            "zh_CN": "沙特阿拉伯",
            "zh_TW": "沙特阿拉伯",
            "en_US": "Saudi Arabia"
        },
        "code": "SA",
        "ips": "395120",
        "cities": []
    },
    "TR": {
        "name": {
            "zh_CN": "土耳其",
            "zh_TW": "土耳其",
            "en_US": "Turkey"
        },
        "code": "TR",
        "ips": "1371440",
        "cities": []
    },
    "BD": {
        "name": {
            "zh_CN": "孟加拉国",
            "zh_TW": "孟加拉國",
            "en_US": "Bangladesh"
        },
        "code": "BD",
        "ips": "330240",
        "cities": []
    },
    "IQ": {
        "name": {
            "zh_CN": "伊拉克",
            "zh_TW": "伊拉克",
            "en_US": "Iraq"
        },
        "code": "IQ",
        "ips": "269080",
        "cities": []
    },
    "AE": {
        "name": {
            "zh_CN": "阿联酋",
            "zh_TW": "阿聯酋",
            "en_US": "UAE"
        },
        "code": "AE",
        "ips": "258760",
        "cities": []
    },
    "PK": {
        "name": {
            "zh_CN": "巴基斯坦",
            "zh_TW": "巴基斯坦",
            "en_US": "Pakistan"
        },
        "code": "PK",
        "ips": "252940",
        "cities": []
    },
    "NP": {
        "name": {
            "zh_CN": "尼泊尔",
            "zh_TW": "尼泊爾",
            "en_US": "Nepal"
        },
        "code": "NP",
        "ips": "252000",
        "cities": []
    },
    "UZ": {
        "name": {
            "zh_CN": "乌兹别克斯坦",
            "zh_TW": "烏茲別克斯坦",
            "en_US": "Uzbekistan"
        },
        "code": "UZ",
        "ips": "234540",
        "cities": []
    },
    "TH": {
        "name": {
            "zh_CN": "泰国",
            "zh_TW": "泰國",
            "en_US": "Thailand"
        },
        "code": "TH",
        "ips": "223960",
        "cities": []
    },
    "BH": {
        "name": {
            "zh_CN": "巴林",
            "zh_TW": "巴林",
            "en_US": "Bahrain"
        },
        "code": "BH",
        "ips": "199800",
        "cities": []
    },
    "IL": {
        "name": {
            "zh_CN": "以色列",
            "zh_TW": "以色列",
            "en_US": "Israel"
        },
        "code": "IL",
        "ips": "176560",
        "cities": []
    },
    "TW": {
        "name": {
            "zh_CN": "中国-台湾",
            "zh_TW": "中國-臺灣",
            "en_US": "Taiwan,China"
        },
        "code": "TW",
        "ips": "117580",
        "cities": []
    },
    "SG": {
        "name": {
            "zh_CN": "新加坡",
            "zh_TW": "新加坡",
            "en_US": "Singapore"
        },
        "code": "SG",
        "ips": "2580",
        "cities": []
    },
    "MM": {
        "name": {
            "zh_CN": "缅甸",
            "zh_TW": "緬甸",
            "en_US": "Myanmar"
        },
        "code": "MM",
        "ips": "109040",
        "cities": []
    },
    "KZ": {
        "name": {
            "zh_CN": "哈萨克斯坦",
            "zh_TW": "哈薩克斯坦",
            "en_US": "Kazakhstan"
        },
        "code": "KZ",
        "ips": "97400",
        "cities": []
    },
    "KW": {
        "name": {
            "zh_CN": "科威特",
            "zh_TW": "科威特",
            "en_US": "Kuwait"
        },
        "code": "KW",
        "ips": "74060",
        "cities": []
    },
    "LB": {
        "name": {
            "zh_CN": "黎巴嫩",
            "zh_TW": "黎巴嫩",
            "en_US": "Lebanon"
        },
        "code": "LB",
        "ips": "68040",
        "cities": []
    },
    "PS": {
        "name": {
            "zh_CN": "巴勒斯坦",
            "zh_TW": "巴勒斯坦",
            "en_US": "Palestine"
        },
        "code": "PS",
        "ips": "65540",
        "cities": []
    },
    "KR": {
        "name": {
            "zh_CN": "韩国",
            "zh_TW": "韓國",
            "en_US": "South Korea"
        },
        "code": "KR",
        "ips": "5511",
        "cities": []
    },
    "AZ": {
        "name": {
            "zh_CN": "阿塞拜疆",
            "zh_TW": "阿塞拜疆",
            "en_US": "Azerbaijan"
        },
        "code": "AZ",
        "ips": "46200",
        "cities": []
    },
    "OM": {
        "name": {
            "zh_CN": "阿曼",
            "zh_TW": "阿曼",
            "en_US": "Oman"
        },
        "code": "OM",
        "ips": "49520",
        "cities": []
    },
    "JO": {
        "name": {
            "zh_CN": "约旦",
            "zh_TW": "約旦",
            "en_US": "Jordan"
        },
        "code": "JO",
        "ips": "45380",
        "cities": []
    },
    "HK": {
        "name": {
            "zh_CN": "中国-香港",
            "zh_TW": "中國-香港",
            "en_US": "Hong Kong,China"
        },
        "code": "HK",
        "ips": "34760",
        "cities": []
    },
    "GE": {
        "name": {
            "zh_CN": "格鲁吉亚",
            "zh_TW": "格魯吉亞",
            "en_US": "Georgia"
        },
        "code": "GE",
        "ips": "34660",
        "cities": []
    },
    "AM": {
        "name": {
            "zh_CN": "亚美尼亚",
            "zh_TW": "亞美尼亞",
            "en_US": "Armenia"
        },
        "code": "AM",
        "ips": "29920",
        "cities": []
    },
    "BN": {
        "name": {
            "zh_CN": "文莱",
            "zh_TW": "文萊",
            "en_US": "Brunei"
        },
        "code": "BN",
        "ips": "0",
        "cities": []
    },
    "KH": {
        "name": {
            "zh_CN": "柬埔寨",
            "zh_TW": "柬埔寨",
            "en_US": "Cambodia"
        },
        "code": "KH",
        "ips": "0",
        "cities": []
    },
    "CN": {
        "name": {
            "zh_CN": "中国",
            "zh_TW": "中國",
            "en_US": "China"
        },
        "code": "CN",
        "ips": "0",
        "cities": []
    },
    "JP": {
        "name": {
            "zh_CN": "日本",
            "zh_TW": "日本",
            "en_US": "Japan"
        },
        "code": "JP",
        "ips": "2570",
        "cities": []
    },
    "QA": {
        "name": {
            "zh_CN": "卡塔尔",
            "zh_TW": "卡塔爾",
            "en_US": "Qatar"
        },
        "code": "QA",
        "ips": "20060",
        "cities": []
    },
    "KG": {
        "name": {
            "zh_CN": "吉尔吉斯斯坦",
            "zh_TW": "吉爾吉斯斯坦",
            "en_US": "Kyrgyzstan"
        },
        "code": "KG",
        "ips": "16700",
        "cities": []
    },
    "IR": {
        "name": {
            "zh_CN": "伊朗",
            "zh_TW": "伊朗",
            "en_US": "Iran"
        },
        "code": "IR",
        "ips": "12100",
        "cities": []
    },
    "MN": {
        "name": {
            "zh_CN": "蒙古",
            "zh_TW": "蒙古",
            "en_US": "Mongolia"
        },
        "code": "MN",
        "ips": "0",
        "cities": []
    },
    "LA": {
        "name": {
            "zh_CN": "老挝",
            "zh_TW": "老撾",
            "en_US": "Laos"
        },
        "code": "LA",
        "ips": "0",
        "cities": []
    },
    "LK": {
        "name": {
            "zh_CN": "斯里兰卡",
            "zh_TW": "斯里蘭卡",
            "en_US": "Sri Lanka"
        },
        "code": "LK",
        "ips": "3060",
        "cities": []
    },
    "MV": {
        "name": {
            "zh_CN": "马尔代夫",
            "zh_TW": "馬爾代夫",
            "en_US": "Maldives"
        },
        "code": "MV",
        "ips": "1240",
        "cities": []
    },
    "TJ": {
        "name": {
            "zh_CN": "塔吉克斯坦",
            "zh_TW": "塔吉克斯坦",
            "en_US": "Tajikistan"
        },
        "code": "TJ",
        "ips": "1160",
        "cities": []
    },
    "YE": {
        "name": {
            "zh_CN": "也门",
            "zh_TW": "也門",
            "en_US": "Yemen"
        },
        "code": "YE",
        "ips": "522",
        "cities": []
    },
    "BT": {
        "name": {
            "zh_CN": "不丹",
            "zh_TW": "不丹",
            "en_US": "Bhutan"
        },
        "code": "BT",
        "ips": "451",
        "cities": []
    },
    "AF": {
        "name": {
            "zh_CN": "阿富汗",
            "zh_TW": "阿富汗",
            "en_US": "Afghanistan"
        },
        "code": "AF",
        "ips": "260",
        "cities": []
    },
    "SY": {
        "name": {
            "zh_CN": "叙利亚",
            "zh_TW": "敘利亞",
            "en_US": "Syria"
        },
        "code": "SY",
        "ips": "20",
        "cities": []
    },
    "RU": {
        "name": {
            "zh_CN": "俄罗斯",
            "zh_TW": "俄羅斯",
            "en_US": "Russia"
        },
        "code": "RU",
        "ips": "0",
        "cities": []
    },
    "FR": {
        "name": {
            "zh_CN": "法国",
            "zh_TW": "法國",
            "en_US": "France"
        },
        "code": "FR",
        "ips": "905340",
        "cities": []
    },
    "ES": {
        "name": {
            "zh_CN": "西班牙",
            "zh_TW": "西班牙",
            "en_US": "Spain"
        },
        "code": "ES",
        "ips": "681780",
        "cities": []
    },
    "IT": {
        "name": {
            "zh_CN": "意大利",
            "zh_TW": "意大利",
            "en_US": "Italy"
        },
        "code": "IT",
        "ips": "442120",
        "cities": []
    },
    "UA": {
        "name": {
            "zh_CN": "乌克兰",
            "zh_TW": "烏克蘭",
            "en_US": "Ukraine"
        },
        "code": "UA",
        "ips": "378400",
        "cities": []
    },
    "DE": {
        "name": {
            "zh_CN": "德国",
            "zh_TW": "德國",
            "en_US": "Germany"
        },
        "code": "DE",
        "ips": "7822",
        "cities": []
    },
    "GB": {
        "name": {
            "zh_CN": "英国",
            "zh_TW": "英國",
            "en_US": "United Kingdom"
        },
        "code": "GB",
        "ips": "13653",
        "cities": []
    },
    "GR": {
        "name": {
            "zh_CN": "希腊",
            "zh_TW": "希臘",
            "en_US": "Greece"
        },
        "code": "GR",
        "ips": "163820",
        "cities": []
    },
    "PT": {
        "name": {
            "zh_CN": "葡萄牙",
            "zh_TW": "葡萄牙",
            "en_US": "Portugal"
        },
        "code": "PT",
        "ips": "134420",
        "cities": []
    },
    "RS": {
        "name": {
            "zh_CN": "塞尔维亚",
            "zh_TW": "塞爾維亞",
            "en_US": "Serbia"
        },
        "code": "RS",
        "ips": "0",
        "cities": []
    },
    "BG": {
        "name": {
            "zh_CN": "保加利亚",
            "zh_TW": "保加利亞",
            "en_US": "Bulgaria"
        },
        "code": "BG",
        "ips": "0",
        "cities": []
    },
    "NL": {
        "name": {
            "zh_CN": "荷兰",
            "zh_TW": "荷蘭",
            "en_US": "Netherlands"
        },
        "code": "NL",
        "ips": "68760",
        "cities": []
    },
    "BY": {
        "name": {
            "zh_CN": "白俄罗斯",
            "zh_TW": "白俄羅斯",
            "en_US": "Belarus"
        },
        "code": "BY",
        "ips": "66960",
        "cities": []
    },
    "BE": {
        "name": {
            "zh_CN": "比利时",
            "zh_TW": "比利時",
            "en_US": "Belgium"
        },
        "code": "BE",
        "ips": "60340",
        "cities": []
    },
    "BA": {
        "name": {
            "zh_CN": "波黑",
            "zh_TW": "波赫",
            "en_US": "BiH"
        },
        "code": "BA",
        "ips": "0",
        "cities": []
    },
    "PL": {
        "name": {
            "zh_CN": "波兰",
            "zh_TW": "波蘭",
            "en_US": "Poland"
        },
        "code": "PL",
        "ips": "52280",
        "cities": []
    },
    "MD": {
        "name": {
            "zh_CN": "摩尔多瓦",
            "zh_TW": "摩爾多瓦",
            "en_US": "Moldova"
        },
        "code": "MD",
        "ips": "50980",
        "cities": []
    },
    "IE": {
        "name": {
            "zh_CN": "爱尔兰",
            "zh_TW": "愛爾蘭",
            "en_US": "Ireland"
        },
        "code": "IE",
        "ips": "0",
        "cities": []
    },
    "CZ": {
        "name": {
            "zh_CN": "捷克共和国",
            "zh_TW": "捷克共和國",
            "en_US": "Czech Republic"
        },
        "code": "CZ",
        "ips": "44880",
        "cities": []
    },
    "AL": {
        "name": {
            "zh_CN": "阿尔巴尼亚",
            "zh_TW": "阿爾巴尼亞",
            "en_US": "Albania"
        },
        "code": "AL",
        "ips": "0",
        "cities": []
    },
    "CY": {
        "name": {
            "zh_CN": "塞浦路斯",
            "zh_TW": "塞浦路斯",
            "en_US": "Cyprus"
        },
        "code": "CY",
        "ips": "28420",
        "cities": []
    },
    "MK": {
        "name": {
            "zh_CN": "北马其顿",
            "zh_TW": "北馬其頓",
            "en_US": "North Macedonia"
        },
        "code": "MK",
        "ips": "7109",
        "cities": []
    },
    "CH": {
        "name": {
            "zh_CN": "瑞士",
            "zh_TW": "瑞士",
            "en_US": "Switzerland"
        },
        "code": "CH",
        "ips": "60574",
        "cities": []
    },
    "SK": {
        "name": {
            "zh_CN": "斯洛伐克",
            "zh_TW": "斯洛伐克",
            "en_US": "Slovakia"
        },
        "code": "SK",
        "ips": "0",
        "cities": []
    },
    "SE": {
        "name": {
            "zh_CN": "瑞典",
            "zh_TW": "瑞典",
            "en_US": "Sweden"
        },
        "code": "SE",
        "ips": "0",
        "cities": []
    },
    "LV": {
        "name": {
            "zh_CN": "拉脱维亚",
            "zh_TW": "拉脫維亞",
            "en_US": "Latvia"
        },
        "code": "LV",
        "ips": "22800",
        "cities": []
    },
    "HU": {
        "name": {
            "zh_CN": "匈牙利",
            "zh_TW": "匈牙利",
            "en_US": "Hungary"
        },
        "code": "HU",
        "ips": "21720",
        "cities": []
    },
    "RO": {
        "name": {
            "zh_CN": "罗马尼亚",
            "zh_TW": "羅馬尼亞",
            "en_US": "Romania"
        },
        "code": "RO",
        "ips": "0",
        "cities": []
    },
    "LT": {
        "name": {
            "zh_CN": "立陶宛",
            "zh_TW": "立陶宛",
            "en_US": "Lithuania"
        },
        "code": "LT",
        "ips": "19560",
        "cities": []
    },
    "HR": {
        "name": {
            "zh_CN": "克罗地亚",
            "zh_TW": "克羅地亞",
            "en_US": "Croatia"
        },
        "code": "HR",
        "ips": "18000",
        "cities": []
    },
    "AT": {
        "name": {
            "zh_CN": "奥地利",
            "zh_TW": "奧地利",
            "en_US": "Austria"
        },
        "code": "AT",
        "ips": "17800",
        "cities": []
    },
    "MT": {
        "name": {
            "zh_CN": "马耳他",
            "zh_TW": "馬耳他",
            "en_US": "Malta"
        },
        "code": "MT",
        "ips": "12680",
        "cities": []
    },
    "EE": {
        "name": {
            "zh_CN": "爱沙尼亚",
            "zh_TW": "愛沙尼亞",
            "en_US": "Estonia"
        },
        "code": "EE",
        "ips": "12200",
        "cities": []
    },
    "XK": {
        "name": {
            "zh_CN": "科索沃",
            "zh_TW": "科索沃",
            "en_US": "Kosovo"
        },
        "code": "XK",
        "ips": "0",
        "cities": []
    },
    "SI": {
        "name": {
            "zh_CN": "斯洛文尼亚",
            "zh_TW": "斯洛文尼亞",
            "en_US": "Slovenia"
        },
        "code": "SI",
        "ips": "0",
        "cities": []
    },
    "NO": {
        "name": {
            "zh_CN": "挪威",
            "zh_TW": "挪威",
            "en_US": "Norway"
        },
        "code": "NO",
        "ips": "0",
        "cities": []
    },
    "LU": {
        "name": {
            "zh_CN": "卢森堡",
            "zh_TW": "盧森堡",
            "en_US": "Luxembourg"
        },
        "code": "LU",
        "ips": "7740",
        "cities": []
    },
    "DK": {
        "name": {
            "zh_CN": "丹麦",
            "zh_TW": "丹麥",
            "en_US": "Denmark"
        },
        "code": "DK",
        "ips": "0",
        "cities": []
    },
    "ME": {
        "name": {
            "zh_CN": "黑山",
            "zh_TW": "黑山",
            "en_US": "Montenegro"
        },
        "code": "ME",
        "ips": "0",
        "cities": []
    },
    "FI": {
        "name": {
            "zh_CN": "芬兰",
            "zh_TW": "芬蘭",
            "en_US": "Finland"
        },
        "code": "FI",
        "ips": "4060",
        "cities": []
    },
    "IS": {
        "name": {
            "zh_CN": "冰岛",
            "zh_TW": "冰島",
            "en_US": "Iceland"
        },
        "code": "IS",
        "ips": "1280",
        "cities": []
    },
    "AD": {
        "name": {
            "zh_CN": "安道尔",
            "zh_TW": "安道爾",
            "en_US": "Andorra"
        },
        "code": "AD",
        "ips": "0",
        "cities": []
    },
    "MX": {
        "name": {
            "zh_CN": "墨西哥",
            "zh_TW": "墨西哥",
            "en_US": "Mexico"
        },
        "code": "MX",
        "ips": "2138920",
        "cities": []
    },
    "US": {
        "name": {
            "zh_CN": "美国",
            "zh_TW": "美國",
            "en_US": "United States"
        },
        "code": "US",
        "ips": "110517",
        "cities": []
    },
    "CA": {
        "name": {
            "zh_CN": "加拿大",
            "zh_TW": "加拿大",
            "en_US": "Canada"
        },
        "code": "CA",
        "ips": "1231080",
        "cities": []
    },
    "DO": {
        "name": {
            "zh_CN": "多米尼加共和国",
            "zh_TW": "多米尼加共和國",
            "en_US": "Dominican Rep."
        },
        "code": "DO",
        "ips": "0",
        "cities": []
    },
    "PA": {
        "name": {
            "zh_CN": "巴拿马",
            "zh_TW": "巴拿馬",
            "en_US": "Panama"
        },
        "code": "PA",
        "ips": "185280",
        "cities": []
    },
    "CR": {
        "name": {
            "zh_CN": "哥斯达黎加",
            "zh_TW": "哥斯達黎加",
            "en_US": "Costa Rica"
        },
        "code": "CR",
        "ips": "0",
        "cities": []
    },
    "GT": {
        "name": {
            "zh_CN": "危地马拉",
            "zh_TW": "危地馬拉",
            "en_US": "Guatemala"
        },
        "code": "GT",
        "ips": "0",
        "cities": []
    },
    "HN": {
        "name": {
            "zh_CN": "洪都拉斯",
            "zh_TW": "洪都拉斯",
            "en_US": "Honduras"
        },
        "code": "HN",
        "ips": "0",
        "cities": []
    },
    "SV": {
        "name": {
            "zh_CN": "救星",
            "zh_TW": "救星",
            "en_US": "El Salvador"
        },
        "code": "SV",
        "ips": "0",
        "cities": []
    },
    "NI": {
        "name": {
            "zh_CN": "尼加拉瓜",
            "zh_TW": "尼加拉瓜",
            "en_US": "Nicaragua"
        },
        "code": "NI",
        "ips": "0",
        "cities": []
    },
    "JM": {
        "name": {
            "zh_CN": "牙买加",
            "zh_TW": "牙買加",
            "en_US": "Jamaica"
        },
        "code": "JM",
        "ips": "34240",
        "cities": []
    },
    "PR": {
        "name": {
            "zh_CN": "波多黎各",
            "zh_TW": "波多黎各",
            "en_US": "Puerto Rico"
        },
        "code": "PR",
        "ips": "0",
        "cities": []
    },
    "TT": {
        "name": {
            "zh_CN": "特立尼达和多巴哥",
            "zh_TW": "特立尼達和多巴哥",
            "en_US": "Trinidad and Tobago"
        },
        "code": "TT",
        "ips": "0",
        "cities": []
    },
    "BB": {
        "name": {
            "zh_CN": "巴巴多斯",
            "zh_TW": "巴巴多斯",
            "en_US": "Barbados"
        },
        "code": "BB",
        "ips": "0",
        "cities": []
    },
    "MQ": {
        "name": {
            "zh_CN": "马提尼克岛",
            "zh_TW": "馬提尼克島",
            "en_US": "Martinique"
        },
        "code": "MQ",
        "ips": "0",
        "cities": []
    },
    "GD": {
        "name": {
            "zh_CN": "格林纳达",
            "zh_TW": "格林納達",
            "en_US": "Grenada"
        },
        "code": "GD",
        "ips": "0",
        "cities": []
    },
    "LC": {
        "name": {
            "zh_CN": "圣卢西亚",
            "zh_TW": "聖盧西亞",
            "en_US": "Saint Lucia"
        },
        "code": "LC",
        "ips": "1440",
        "cities": []
    },
    "GP": {
        "name": {
            "zh_CN": "瓜德罗普岛",
            "zh_TW": "瓜德羅普島",
            "en_US": "Guadeloupe"
        },
        "code": "GP",
        "ips": "0",
        "cities": []
    },
    "AG": {
        "name": {
            "zh_CN": "安提瓜和巴布达",
            "zh_TW": "安提瓜和巴布達",
            "en_US": "Antigua and Barbuda"
        },
        "code": "AG",
        "ips": "0",
        "cities": []
    },
    "BS": {
        "name": {
            "zh_CN": "巴哈马",
            "zh_TW": "巴哈馬",
            "en_US": "Bahamas"
        },
        "code": "BS",
        "ips": "0",
        "cities": []
    },
    "HT": {
        "name": {
            "zh_CN": "海地",
            "zh_TW": "海地",
            "en_US": "Haiti"
        },
        "code": "HT",
        "ips": "0",
        "cities": []
    },
    "BZ": {
        "name": {
            "zh_CN": "伯利兹",
            "zh_TW": "伯利茲",
            "en_US": "Belize"
        },
        "code": "BZ",
        "ips": "0",
        "cities": []
    },
    "CU": {
        "name": {
            "zh_CN": "古巴",
            "zh_TW": "古巴",
            "en_US": "Cuba"
        },
        "code": "CU",
        "ips": "0",
        "cities": []
    },
    "VC": {
        "name": {
            "zh_CN": "圣文森特",
            "zh_TW": "聖文森特",
            "en_US": "St. Vincent"
        },
        "code": "VC",
        "ips": "0",
        "cities": []
    },
    "AI": {
        "name": {
            "zh_CN": "安圭拉",
            "zh_TW": "安圭拉",
            "en_US": "Anguilla"
        },
        "code": "AI",
        "ips": "0",
        "cities": []
    },
    "BM": {
        "name": {
            "zh_CN": "百慕大",
            "zh_TW": "百慕大",
            "en_US": "Bermuda"
        },
        "code": "BM",
        "ips": "0",
        "cities": []
    },
    "KN": {
        "name": {
            "zh_CN": "圣基茨和尼维斯",
            "zh_TW": "聖基茨和尼維斯",
            "en_US": "Saint Kitts and Nevis"
        },
        "code": "KN",
        "ips": "0",
        "cities": []
    },
    "KY": {
        "name": {
            "zh_CN": "开曼群岛",
            "zh_TW": "開曼羣島",
            "en_US": "Cayman Islands"
        },
        "code": "KY",
        "ips": "0",
        "cities": []
    },
    "BR": {
        "name": {
            "zh_CN": "巴西",
            "zh_TW": "巴西",
            "en_US": "Brazil"
        },
        "code": "BR",
        "ips": "47268060",
        "cities": []
    },
    "EC": {
        "name": {
            "zh_CN": "厄瓜多尔",
            "zh_TW": "厄瓜多爾",
            "en_US": "Ecuador"
        },
        "code": "EC",
        "ips": "0",
        "cities": []
    },
    "AR": {
        "name": {
            "zh_CN": "阿根廷",
            "zh_TW": "阿根廷",
            "en_US": "Argentina"
        },
        "code": "AR",
        "ips": "1034820",
        "cities": []
    },
    "CO": {
        "name": {
            "zh_CN": "哥伦比亚",
            "zh_TW": "哥倫比亞",
            "en_US": "Colombia"
        },
        "code": "CO",
        "ips": "528740",
        "cities": []
    },
    "UY": {
        "name": {
            "zh_CN": "乌拉圭",
            "zh_TW": "烏拉圭",
            "en_US": "Uruguay"
        },
        "code": "UY",
        "ips": "0",
        "cities": []
    },
    "PE": {
        "name": {
            "zh_CN": "秘鲁",
            "zh_TW": "祕魯",
            "en_US": "Peru"
        },
        "code": "PE",
        "ips": "269440",
        "cities": []
    },
    "VE": {
        "name": {
            "zh_CN": "委内瑞拉",
            "zh_TW": "委內瑞拉",
            "en_US": "Venezuela"
        },
        "code": "VE",
        "ips": "243500",
        "cities": []
    },
    "CL": {
        "name": {
            "zh_CN": "智利",
            "zh_TW": "智利",
            "en_US": "Chile"
        },
        "code": "CL",
        "ips": "162860",
        "cities": []
    },
    "BO": {
        "name": {
            "zh_CN": "玻利维亚",
            "zh_TW": "玻利維亞",
            "en_US": "Bolivia"
        },
        "code": "BO",
        "ips": "0",
        "cities": []
    },
    "PY": {
        "name": {
            "zh_CN": "巴拉圭",
            "zh_TW": "巴拉圭",
            "en_US": "Paraguay"
        },
        "code": "PY",
        "ips": "0",
        "cities": []
    },
    "GY": {
        "name": {
            "zh_CN": "圭亚那",
            "zh_TW": "圭亞那",
            "en_US": "Guyana"
        },
        "code": "GY",
        "ips": "0",
        "cities": []
    },
    "SR": {
        "name": {
            "zh_CN": "苏里南",
            "zh_TW": "蘇里南",
            "en_US": "Suriname"
        },
        "code": "SR",
        "ips": "0",
        "cities": []
    },
    "GF": {
        "name": {
            "zh_CN": "法属圭亚那",
            "zh_TW": "法屬圭亞那",
            "en_US": "French Guiana"
        },
        "code": "GF",
        "ips": "0",
        "cities": []
    },
    "AU": {
        "name": {
            "zh_CN": "澳大利亚",
            "zh_TW": "澳大利亞",
            "en_US": "Australia"
        },
        "code": "AU",
        "ips": "101440",
        "cities": []
    },
    "NZ": {
        "name": {
            "zh_CN": "新西兰",
            "zh_TW": "新西蘭",
            "en_US": "New Zealand"
        },
        "code": "NZ",
        "ips": "22220",
        "cities": []
    },
    "FJ": {
        "name": {
            "zh_CN": "斐济",
            "zh_TW": "斐濟",
            "en_US": "Fiji"
        },
        "code": "FJ",
        "ips": "0",
        "cities": []
    },
    "GU": {
        "name": {
            "zh_CN": "关岛",
            "zh_TW": "關島",
            "en_US": "Guam"
        },
        "code": "GU",
        "ips": "0",
        "cities": []
    },
    "NC": {
        "name": {
            "zh_CN": "新喀里多尼亚",
            "zh_TW": "新喀里多尼亞",
            "en_US": "New Caledonia"
        },
        "code": "NC",
        "ips": "0",
        "cities": []
    },
    "ZA": {
        "name": {
            "zh_CN": "南非",
            "zh_TW": "南非",
            "en_US": "South Africa"
        },
        "code": "ZA",
        "ips": "700200",
        "cities": []
    },
    "MA": {
        "name": {
            "zh_CN": "摩洛哥",
            "zh_TW": "摩洛哥",
            "en_US": "Morocco"
        },
        "code": "MA",
        "ips": "0",
        "cities": []
    },
    "TN": {
        "name": {
            "zh_CN": "突尼斯",
            "zh_TW": "突尼斯",
            "en_US": "Tunisia"
        },
        "code": "TN",
        "ips": "0",
        "cities": []
    },
    "DZ": {
        "name": {
            "zh_CN": "阿尔及利亚",
            "zh_TW": "阿爾及利亞",
            "en_US": "Algeria"
        },
        "code": "DZ",
        "ips": "94320",
        "cities": []
    },
    "KE": {
        "name": {
            "zh_CN": "肯尼亚",
            "zh_TW": "肯尼亞",
            "en_US": "Kenya"
        },
        "code": "KE",
        "ips": "91580",
        "cities": []
    },
    "EG": {
        "name": {
            "zh_CN": "埃及",
            "zh_TW": "埃及",
            "en_US": "Egypt"
        },
        "code": "EG",
        "ips": "0",
        "cities": []
    },
    "SN": {
        "name": {
            "zh_CN": "塞内加尔",
            "zh_TW": "塞內加爾",
            "en_US": "Senegal"
        },
        "code": "SN",
        "ips": "0",
        "cities": []
    },
    "ML": {
        "name": {
            "zh_CN": "马里",
            "zh_TW": "馬利",
            "en_US": "Mali"
        },
        "code": "ML",
        "ips": "0",
        "cities": []
    },
    "CI": {
        "name": {
            "zh_CN": "科特迪瓦",
            "zh_TW": "科特迪瓦",
            "en_US": "Ivory Coast"
        },
        "code": "CI",
        "ips": "0",
        "cities": []
    },
    "GA": {
        "name": {
            "zh_CN": "加蓬",
            "zh_TW": "加蓬",
            "en_US": "Gabon"
        },
        "code": "GA",
        "ips": "0",
        "cities": []
    },
    "CG": {
        "name": {
            "zh_CN": "刚果共和国",
            "zh_TW": "剛果共和國",
            "en_US": "Congo Republic"
        },
        "code": "CG",
        "ips": "0",
        "cities": []
    },
    "BW": {
        "name": {
            "zh_CN": "博茨瓦纳",
            "zh_TW": "博茨瓦納",
            "en_US": "Botswana"
        },
        "code": "BW",
        "ips": "0",
        "cities": []
    },
    "AO": {
        "name": {
            "zh_CN": "安哥拉",
            "zh_TW": "安哥拉",
            "en_US": "Angola"
        },
        "code": "AO",
        "ips": "0",
        "cities": []
    },
    "TG": {
        "name": {
            "zh_CN": "多哥",
            "zh_TW": "多哥",
            "en_US": "Togo"
        },
        "code": "TG",
        "ips": "0",
        "cities": []
    },
    "MU": {
        "name": {
            "zh_CN": "毛里求斯",
            "zh_TW": "毛里求斯",
            "en_US": "Mauritius"
        },
        "code": "MU",
        "ips": "0",
        "cities": []
    },
    "NG": {
        "name": {
            "zh_CN": "尼日利亚",
            "zh_TW": "尼日利亞",
            "en_US": "Nigeria"
        },
        "code": "NG",
        "ips": "8600",
        "cities": []
    },
    "RE": {
        "name": {
            "zh_CN": "留尼汪",
            "zh_TW": "留尼汪",
            "en_US": "Reunion"
        },
        "code": "RE",
        "ips": "0",
        "cities": []
    },
    "BF": {
        "name": {
            "zh_CN": "布基纳法索",
            "zh_TW": "布基納法索",
            "en_US": "Burkina Faso"
        },
        "code": "BF",
        "ips": "0",
        "cities": []
    },
    "LY": {
        "name": {
            "zh_CN": "利比亚",
            "zh_TW": "利比亞",
            "en_US": "Libya"
        },
        "code": "LY",
        "ips": "0",
        "cities": []
    },
    "MZ": {
        "name": {
            "zh_CN": "莫桑比克",
            "zh_TW": "莫桑比克",
            "en_US": "Mozambique"
        },
        "code": "MZ",
        "ips": "3160",
        "cities": []
    },
    "CM": {
        "name": {
            "zh_CN": "喀麦隆",
            "zh_TW": "喀麥隆",
            "en_US": "Cameroon"
        },
        "code": "CM",
        "ips": "0",
        "cities": []
    },
    "ZW": {
        "name": {
            "zh_CN": "津巴布韦",
            "zh_TW": "津巴布韋",
            "en_US": "Zimbabwe"
        },
        "code": "ZW",
        "ips": "0",
        "cities": []
    },
    "GH": {
        "name": {
            "zh_CN": "加纳",
            "zh_TW": "加納",
            "en_US": "Ghana"
        },
        "code": "GH",
        "ips": "1640",
        "cities": []
    },
    "MG": {
        "name": {
            "zh_CN": "马达加斯加",
            "zh_TW": "馬達加斯加",
            "en_US": "Madagascar"
        },
        "code": "MG",
        "ips": "1480",
        "cities": []
    },
    "BJ": {
        "name": {
            "zh_CN": "贝宁",
            "zh_TW": "貝寧",
            "en_US": "Benin"
        },
        "code": "BJ",
        "ips": "0",
        "cities": []
    },
    "ET": {
        "name": {
            "zh_CN": "埃塞俄比亚",
            "zh_TW": "埃塞俄比亞",
            "en_US": "Ethiopia"
        },
        "code": "ET",
        "ips": "1382",
        "cities": []
    },
    "CD": {
        "name": {
            "zh_CN": "刚果民主共和国",
            "zh_TW": "剛果民主共和國",
            "en_US": "DR Congo"
        },
        "code": "CD",
        "ips": "0",
        "cities": []
    },
    "CV": {
        "name": {
            "zh_CN": "佛得角",
            "zh_TW": "佛得角",
            "en_US": "Cape Verde"
        },
        "code": "CV",
        "ips": "0",
        "cities": []
    },
    "UG": {
        "name": {
            "zh_CN": "乌干达",
            "zh_TW": "烏干達",
            "en_US": "Uganda"
        },
        "code": "UG",
        "ips": "0",
        "cities": []
    },
    "GM": {
        "name": {
            "zh_CN": "冈比亚",
            "zh_TW": "岡比亞",
            "en_US": "Gambia"
        },
        "code": "GM",
        "ips": "0",
        "cities": []
    },
    "TZ": {
        "name": {
            "zh_CN": "坦桑尼亚",
            "zh_TW": "坦桑尼亞",
            "en_US": "Tanzania"
        },
        "code": "TZ",
        "ips": "0",
        "cities": []
    },
    "NA": {
        "name": {
            "zh_CN": "纳米比亚",
            "zh_TW": "納米比亞",
            "en_US": "Namibia"
        },
        "code": "NA",
        "ips": "0",
        "cities": []
    },
    "SO": {
        "name": {
            "zh_CN": "索马里",
            "zh_TW": "索馬里",
            "en_US": "Somalia"
        },
        "code": "SO",
        "ips": "0",
        "cities": []
    },
    "ZM": {
        "name": {
            "zh_CN": "赞比亚",
            "zh_TW": "贊比亞",
            "en_US": "Zambia"
        },
        "code": "ZM",
        "ips": "0",
        "cities": []
    },
    "GN": {
        "name": {
            "zh_CN": "几内亚",
            "zh_TW": "幾內亞",
            "en_US": "Guinea"
        },
        "code": "GN",
        "ips": "0",
        "cities": []
    },
    "ST": {
        "name": {
            "zh_CN": "圣多美和普林西比",
            "zh_TW": "聖多美和普林西比",
            "en_US": "Sao Tome and Principe"
        },
        "code": "ST",
        "ips": "0",
        "cities": []
    },
    "RW": {
        "name": {
            "zh_CN": "卢旺达",
            "zh_TW": "盧旺達",
            "en_US": "Rwanda"
        },
        "code": "RW",
        "ips": "0",
        "cities": []
    },
    "SC": {
        "name": {
            "zh_CN": "塞舌尔",
            "zh_TW": "塞舌爾",
            "en_US": "Seychelles"
        },
        "code": "SC",
        "ips": "0",
        "cities": []
    },
    "YT": {
        "name": {
            "zh_CN": "马约特岛",
            "zh_TW": "馬約特島",
            "en_US": "Mayotte"
        },
        "code": "YT",
        "ips": "0",
        "cities": []
    },
    "MW": {
        "name": {
            "zh_CN": "马拉维",
            "zh_TW": "馬拉維",
            "en_US": "Malawi"
        },
        "code": "MW",
        "ips": "0",
        "cities": []
    },
    "DJ": {
        "name": {
            "zh_CN": "吉布提",
            "zh_TW": "吉布提",
            "en_US": "Djibouti"
        },
        "code": "DJ",
        "ips": "0",
        "cities": []
    },
    "SD": {
        "name": {
            "zh_CN": "苏丹",
            "zh_TW": "蘇丹",
            "en_US": "Sudan"
        },
        "code": "SD",
        "ips": "0",
        "cities": []
    }
}

export const CountriesListByName = {
    "Philippines": {
        "name": {
            "zh_CN": "菲律宾",
            "zh_TW": "菲律賓",
            "en_US": "Philippines"
        },
        "code": "PH",
        "ips": "1616220",
        "cities": []
    },
    "Malaysia": {
        "name": {
            "zh_CN": "马来西亚",
            "zh_TW": "馬來西亞",
            "en_US": "Malaysia"
        },
        "code": "MY",
        "ips": "1520080",
        "cities": []
    },
    "Indonesia": {
        "name": {
            "zh_CN": "印度尼西亚",
            "zh_TW": "印度尼西亞",
            "en_US": "Indonesia"
        },
        "code": "ID",
        "ips": "908800",
        "cities": []
    },
    "Vietnam": {
        "name": {
            "zh_CN": "越南",
            "zh_TW": "越南",
            "en_US": "Vietnam"
        },
        "code": "VN",
        "ips": "520960",
        "cities": []
    },
    "India": {
        "name": {
            "zh_CN": "印度",
            "zh_TW": "印度",
            "en_US": "India"
        },
        "code": "IN",
        "ips": "67668",
        "cities": []
    },
    "Saudi Arabia": {
        "name": {
            "zh_CN": "沙特阿拉伯",
            "zh_TW": "沙特阿拉伯",
            "en_US": "Saudi Arabia"
        },
        "code": "SA",
        "ips": "395120",
        "cities": []
    },
    "Turkey": {
        "name": {
            "zh_CN": "土耳其",
            "zh_TW": "土耳其",
            "en_US": "Turkey"
        },
        "code": "TR",
        "ips": "1371440",
        "cities": []
    },
    "Bangladesh": {
        "name": {
            "zh_CN": "孟加拉国",
            "zh_TW": "孟加拉國",
            "en_US": "Bangladesh"
        },
        "code": "BD",
        "ips": "330240",
        "cities": []
    },
    "Iraq": {
        "name": {
            "zh_CN": "伊拉克",
            "zh_TW": "伊拉克",
            "en_US": "Iraq"
        },
        "code": "IQ",
        "ips": "269080",
        "cities": []
    },
    "UAE": {
        "name": {
            "zh_CN": "阿联酋",
            "zh_TW": "阿聯酋",
            "en_US": "UAE"
        },
        "code": "AE",
        "ips": "258760",
        "cities": []
    },
    "Pakistan": {
        "name": {
            "zh_CN": "巴基斯坦",
            "zh_TW": "巴基斯坦",
            "en_US": "Pakistan"
        },
        "code": "PK",
        "ips": "252940",
        "cities": []
    },
    "Nepal": {
        "name": {
            "zh_CN": "尼泊尔",
            "zh_TW": "尼泊爾",
            "en_US": "Nepal"
        },
        "code": "NP",
        "ips": "252000",
        "cities": []
    },
    "Uzbekistan": {
        "name": {
            "zh_CN": "乌兹别克斯坦",
            "zh_TW": "烏茲別克斯坦",
            "en_US": "Uzbekistan"
        },
        "code": "UZ",
        "ips": "234540",
        "cities": []
    },
    "Thailand": {
        "name": {
            "zh_CN": "泰国",
            "zh_TW": "泰國",
            "en_US": "Thailand"
        },
        "code": "TH",
        "ips": "223960",
        "cities": []
    },
    "Bahrain": {
        "name": {
            "zh_CN": "巴林",
            "zh_TW": "巴林",
            "en_US": "Bahrain"
        },
        "code": "BH",
        "ips": "199800",
        "cities": []
    },
    "Israel": {
        "name": {
            "zh_CN": "以色列",
            "zh_TW": "以色列",
            "en_US": "Israel"
        },
        "code": "IL",
        "ips": "176560",
        "cities": []
    },
    "Taiwan,China": {
        "name": {
            "zh_CN": "中国-台湾",
            "zh_TW": "中國-臺灣",
            "en_US": "Taiwan,China"
        },
        "code": "TW",
        "ips": "117580",
        "cities": []
    },
    "Singapore": {
        "name": {
            "zh_CN": "新加坡",
            "zh_TW": "新加坡",
            "en_US": "Singapore"
        },
        "code": "SG",
        "ips": "2580",
        "cities": []
    },
    "Myanmar": {
        "name": {
            "zh_CN": "缅甸",
            "zh_TW": "緬甸",
            "en_US": "Myanmar"
        },
        "code": "MM",
        "ips": "109040",
        "cities": []
    },
    "Kazakhstan": {
        "name": {
            "zh_CN": "哈萨克斯坦",
            "zh_TW": "哈薩克斯坦",
            "en_US": "Kazakhstan"
        },
        "code": "KZ",
        "ips": "97400",
        "cities": []
    },
    "Kuwait": {
        "name": {
            "zh_CN": "科威特",
            "zh_TW": "科威特",
            "en_US": "Kuwait"
        },
        "code": "KW",
        "ips": "74060",
        "cities": []
    },
    "Lebanon": {
        "name": {
            "zh_CN": "黎巴嫩",
            "zh_TW": "黎巴嫩",
            "en_US": "Lebanon"
        },
        "code": "LB",
        "ips": "68040",
        "cities": []
    },
    "Palestine": {
        "name": {
            "zh_CN": "巴勒斯坦",
            "zh_TW": "巴勒斯坦",
            "en_US": "Palestine"
        },
        "code": "PS",
        "ips": "65540",
        "cities": []
    },
    "South Korea": {
        "name": {
            "zh_CN": "韩国",
            "zh_TW": "韓國",
            "en_US": "South Korea"
        },
        "code": "KR",
        "ips": "5511",
        "cities": []
    },
    "Azerbaijan": {
        "name": {
            "zh_CN": "阿塞拜疆",
            "zh_TW": "阿塞拜疆",
            "en_US": "Azerbaijan"
        },
        "code": "AZ",
        "ips": "46200",
        "cities": []
    },
    "Oman": {
        "name": {
            "zh_CN": "阿曼",
            "zh_TW": "阿曼",
            "en_US": "Oman"
        },
        "code": "OM",
        "ips": "49520",
        "cities": []
    },
    "Jordan": {
        "name": {
            "zh_CN": "约旦",
            "zh_TW": "約旦",
            "en_US": "Jordan"
        },
        "code": "JO",
        "ips": "45380",
        "cities": []
    },
    "Hong Kong,China": {
        "name": {
            "zh_CN": "中国-香港",
            "zh_TW": "中國-香港",
            "en_US": "Hong Kong,China"
        },
        "code": "HK",
        "ips": "34760",
        "cities": []
    },
    "Georgia": {
        "name": {
            "zh_CN": "格鲁吉亚",
            "zh_TW": "格魯吉亞",
            "en_US": "Georgia"
        },
        "code": "GE",
        "ips": "34660",
        "cities": []
    },
    "Armenia": {
        "name": {
            "zh_CN": "亚美尼亚",
            "zh_TW": "亞美尼亞",
            "en_US": "Armenia"
        },
        "code": "AM",
        "ips": "29920",
        "cities": []
    },
    "Brunei": {
        "name": {
            "zh_CN": "文莱",
            "zh_TW": "文萊",
            "en_US": "Brunei"
        },
        "code": "BN",
        "ips": "0",
        "cities": []
    },
    "Cambodia": {
        "name": {
            "zh_CN": "柬埔寨",
            "zh_TW": "柬埔寨",
            "en_US": "Cambodia"
        },
        "code": "KH",
        "ips": "0",
        "cities": []
    },
    "China": {
        "name": {
            "zh_CN": "中国",
            "zh_TW": "中國",
            "en_US": "China"
        },
        "code": "CN",
        "ips": "0",
        "cities": []
    },
    "Japan": {
        "name": {
            "zh_CN": "日本",
            "zh_TW": "日本",
            "en_US": "Japan"
        },
        "code": "JP",
        "ips": "2570",
        "cities": []
    },
    "Qatar": {
        "name": {
            "zh_CN": "卡塔尔",
            "zh_TW": "卡塔爾",
            "en_US": "Qatar"
        },
        "code": "QA",
        "ips": "20060",
        "cities": []
    },
    "Kyrgyzstan": {
        "name": {
            "zh_CN": "吉尔吉斯斯坦",
            "zh_TW": "吉爾吉斯斯坦",
            "en_US": "Kyrgyzstan"
        },
        "code": "KG",
        "ips": "16700",
        "cities": []
    },
    "Iran": {
        "name": {
            "zh_CN": "伊朗",
            "zh_TW": "伊朗",
            "en_US": "Iran"
        },
        "code": "IR",
        "ips": "12100",
        "cities": []
    },
    "Mongolia": {
        "name": {
            "zh_CN": "蒙古",
            "zh_TW": "蒙古",
            "en_US": "Mongolia"
        },
        "code": "MN",
        "ips": "0",
        "cities": []
    },
    "Laos": {
        "name": {
            "zh_CN": "老挝",
            "zh_TW": "老撾",
            "en_US": "Laos"
        },
        "code": "LA",
        "ips": "0",
        "cities": []
    },
    "Sri Lanka": {
        "name": {
            "zh_CN": "斯里兰卡",
            "zh_TW": "斯里蘭卡",
            "en_US": "Sri Lanka"
        },
        "code": "LK",
        "ips": "3060",
        "cities": []
    },
    "Maldives": {
        "name": {
            "zh_CN": "马尔代夫",
            "zh_TW": "馬爾代夫",
            "en_US": "Maldives"
        },
        "code": "MV",
        "ips": "1240",
        "cities": []
    },
    "Tajikistan": {
        "name": {
            "zh_CN": "塔吉克斯坦",
            "zh_TW": "塔吉克斯坦",
            "en_US": "Tajikistan"
        },
        "code": "TJ",
        "ips": "1160",
        "cities": []
    },
    "Yemen": {
        "name": {
            "zh_CN": "也门",
            "zh_TW": "也門",
            "en_US": "Yemen"
        },
        "code": "YE",
        "ips": "522",
        "cities": []
    },
    "Bhutan": {
        "name": {
            "zh_CN": "不丹",
            "zh_TW": "不丹",
            "en_US": "Bhutan"
        },
        "code": "BT",
        "ips": "451",
        "cities": []
    },
    "Afghanistan": {
        "name": {
            "zh_CN": "阿富汗",
            "zh_TW": "阿富汗",
            "en_US": "Afghanistan"
        },
        "code": "AF",
        "ips": "260",
        "cities": []
    },
    "Syria": {
        "name": {
            "zh_CN": "叙利亚",
            "zh_TW": "敘利亞",
            "en_US": "Syria"
        },
        "code": "SY",
        "ips": "20",
        "cities": []
    },
    "Russia": {
        "name": {
            "zh_CN": "俄罗斯",
            "zh_TW": "俄羅斯",
            "en_US": "Russia"
        },
        "code": "RU",
        "ips": "0",
        "cities": []
    },
    "France": {
        "name": {
            "zh_CN": "法国",
            "zh_TW": "法國",
            "en_US": "France"
        },
        "code": "FR",
        "ips": "905340",
        "cities": []
    },
    "Spain": {
        "name": {
            "zh_CN": "西班牙",
            "zh_TW": "西班牙",
            "en_US": "Spain"
        },
        "code": "ES",
        "ips": "681780",
        "cities": []
    },
    "Italy": {
        "name": {
            "zh_CN": "意大利",
            "zh_TW": "意大利",
            "en_US": "Italy"
        },
        "code": "IT",
        "ips": "442120",
        "cities": []
    },
    "Ukraine": {
        "name": {
            "zh_CN": "乌克兰",
            "zh_TW": "烏克蘭",
            "en_US": "Ukraine"
        },
        "code": "UA",
        "ips": "378400",
        "cities": []
    },
    "Germany": {
        "name": {
            "zh_CN": "德国",
            "zh_TW": "德國",
            "en_US": "Germany"
        },
        "code": "DE",
        "ips": "7822",
        "cities": []
    },
    "United Kingdom": {
        "name": {
            "zh_CN": "英国",
            "zh_TW": "英國",
            "en_US": "United Kingdom"
        },
        "code": "GB",
        "ips": "13653",
        "cities": []
    },
    "Greece": {
        "name": {
            "zh_CN": "希腊",
            "zh_TW": "希臘",
            "en_US": "Greece"
        },
        "code": "GR",
        "ips": "163820",
        "cities": []
    },
    "Portugal": {
        "name": {
            "zh_CN": "葡萄牙",
            "zh_TW": "葡萄牙",
            "en_US": "Portugal"
        },
        "code": "PT",
        "ips": "134420",
        "cities": []
    },
    "Serbia": {
        "name": {
            "zh_CN": "塞尔维亚",
            "zh_TW": "塞爾維亞",
            "en_US": "Serbia"
        },
        "code": "RS",
        "ips": "0",
        "cities": []
    },
    "Bulgaria": {
        "name": {
            "zh_CN": "保加利亚",
            "zh_TW": "保加利亞",
            "en_US": "Bulgaria"
        },
        "code": "BG",
        "ips": "0",
        "cities": []
    },
    "Netherlands": {
        "name": {
            "zh_CN": "荷兰",
            "zh_TW": "荷蘭",
            "en_US": "Netherlands"
        },
        "code": "NL",
        "ips": "68760",
        "cities": []
    },
    "Belarus": {
        "name": {
            "zh_CN": "白俄罗斯",
            "zh_TW": "白俄羅斯",
            "en_US": "Belarus"
        },
        "code": "BY",
        "ips": "66960",
        "cities": []
    },
    "Belgium": {
        "name": {
            "zh_CN": "比利时",
            "zh_TW": "比利時",
            "en_US": "Belgium"
        },
        "code": "BE",
        "ips": "60340",
        "cities": []
    },
    "BiH": {
        "name": {
            "zh_CN": "波黑",
            "zh_TW": "波赫",
            "en_US": "BiH"
        },
        "code": "BA",
        "ips": "0",
        "cities": []
    },
    "Poland": {
        "name": {
            "zh_CN": "波兰",
            "zh_TW": "波蘭",
            "en_US": "Poland"
        },
        "code": "PL",
        "ips": "52280",
        "cities": []
    },
    "Moldova": {
        "name": {
            "zh_CN": "摩尔多瓦",
            "zh_TW": "摩爾多瓦",
            "en_US": "Moldova"
        },
        "code": "MD",
        "ips": "50980",
        "cities": []
    },
    "Ireland": {
        "name": {
            "zh_CN": "爱尔兰",
            "zh_TW": "愛爾蘭",
            "en_US": "Ireland"
        },
        "code": "IE",
        "ips": "0",
        "cities": []
    },
    "Czech Republic": {
        "name": {
            "zh_CN": "捷克共和国",
            "zh_TW": "捷克共和國",
            "en_US": "Czech Republic"
        },
        "code": "CZ",
        "ips": "44880",
        "cities": []
    },
    "Albania": {
        "name": {
            "zh_CN": "阿尔巴尼亚",
            "zh_TW": "阿爾巴尼亞",
            "en_US": "Albania"
        },
        "code": "AL",
        "ips": "0",
        "cities": []
    },
    "Cyprus": {
        "name": {
            "zh_CN": "塞浦路斯",
            "zh_TW": "塞浦路斯",
            "en_US": "Cyprus"
        },
        "code": "CY",
        "ips": "28420",
        "cities": []
    },
    "North Macedonia": {
        "name": {
            "zh_CN": "北马其顿",
            "zh_TW": "北馬其頓",
            "en_US": "North Macedonia"
        },
        "code": "MK",
        "ips": "7109",
        "cities": []
    },
    "Switzerland": {
        "name": {
            "zh_CN": "瑞士",
            "zh_TW": "瑞士",
            "en_US": "Switzerland"
        },
        "code": "CH",
        "ips": "60574",
        "cities": []
    },
    "Slovakia": {
        "name": {
            "zh_CN": "斯洛伐克",
            "zh_TW": "斯洛伐克",
            "en_US": "Slovakia"
        },
        "code": "SK",
        "ips": "0",
        "cities": []
    },
    "Sweden": {
        "name": {
            "zh_CN": "瑞典",
            "zh_TW": "瑞典",
            "en_US": "Sweden"
        },
        "code": "SE",
        "ips": "0",
        "cities": []
    },
    "Latvia": {
        "name": {
            "zh_CN": "拉脱维亚",
            "zh_TW": "拉脫維亞",
            "en_US": "Latvia"
        },
        "code": "LV",
        "ips": "22800",
        "cities": []
    },
    "Hungary": {
        "name": {
            "zh_CN": "匈牙利",
            "zh_TW": "匈牙利",
            "en_US": "Hungary"
        },
        "code": "HU",
        "ips": "21720",
        "cities": []
    },
    "Romania": {
        "name": {
            "zh_CN": "罗马尼亚",
            "zh_TW": "羅馬尼亞",
            "en_US": "Romania"
        },
        "code": "RO",
        "ips": "0",
        "cities": []
    },
    "Lithuania": {
        "name": {
            "zh_CN": "立陶宛",
            "zh_TW": "立陶宛",
            "en_US": "Lithuania"
        },
        "code": "LT",
        "ips": "19560",
        "cities": []
    },
    "Croatia": {
        "name": {
            "zh_CN": "克罗地亚",
            "zh_TW": "克羅地亞",
            "en_US": "Croatia"
        },
        "code": "HR",
        "ips": "18000",
        "cities": []
    },
    "Austria": {
        "name": {
            "zh_CN": "奥地利",
            "zh_TW": "奧地利",
            "en_US": "Austria"
        },
        "code": "AT",
        "ips": "17800",
        "cities": []
    },
    "Malta": {
        "name": {
            "zh_CN": "马耳他",
            "zh_TW": "馬耳他",
            "en_US": "Malta"
        },
        "code": "MT",
        "ips": "12680",
        "cities": []
    },
    "Estonia": {
        "name": {
            "zh_CN": "爱沙尼亚",
            "zh_TW": "愛沙尼亞",
            "en_US": "Estonia"
        },
        "code": "EE",
        "ips": "12200",
        "cities": []
    },
    "Kosovo": {
        "name": {
            "zh_CN": "科索沃",
            "zh_TW": "科索沃",
            "en_US": "Kosovo"
        },
        "code": "XK",
        "ips": "0",
        "cities": []
    },
    "Slovenia": {
        "name": {
            "zh_CN": "斯洛文尼亚",
            "zh_TW": "斯洛文尼亞",
            "en_US": "Slovenia"
        },
        "code": "SI",
        "ips": "0",
        "cities": []
    },
    "Norway": {
        "name": {
            "zh_CN": "挪威",
            "zh_TW": "挪威",
            "en_US": "Norway"
        },
        "code": "NO",
        "ips": "0",
        "cities": []
    },
    "Luxembourg": {
        "name": {
            "zh_CN": "卢森堡",
            "zh_TW": "盧森堡",
            "en_US": "Luxembourg"
        },
        "code": "LU",
        "ips": "7740",
        "cities": []
    },
    "Denmark": {
        "name": {
            "zh_CN": "丹麦",
            "zh_TW": "丹麥",
            "en_US": "Denmark"
        },
        "code": "DK",
        "ips": "0",
        "cities": []
    },
    "Montenegro": {
        "name": {
            "zh_CN": "黑山",
            "zh_TW": "黑山",
            "en_US": "Montenegro"
        },
        "code": "ME",
        "ips": "0",
        "cities": []
    },
    "Finland": {
        "name": {
            "zh_CN": "芬兰",
            "zh_TW": "芬蘭",
            "en_US": "Finland"
        },
        "code": "FI",
        "ips": "4060",
        "cities": []
    },
    "Iceland": {
        "name": {
            "zh_CN": "冰岛",
            "zh_TW": "冰島",
            "en_US": "Iceland"
        },
        "code": "IS",
        "ips": "1280",
        "cities": []
    },
    "Andorra": {
        "name": {
            "zh_CN": "安道尔",
            "zh_TW": "安道爾",
            "en_US": "Andorra"
        },
        "code": "AD",
        "ips": "0",
        "cities": []
    },
    "Mexico": {
        "name": {
            "zh_CN": "墨西哥",
            "zh_TW": "墨西哥",
            "en_US": "Mexico"
        },
        "code": "MX",
        "ips": "2138920",
        "cities": []
    },
    "US": {
        "name": {
            "zh_CN": "美国",
            "zh_TW": "美國",
            "en_US": "United States"
        },
        "code": "US",
        "ips": "110517",
        "cities": []
    },
    "Canada": {
        "name": {
            "zh_CN": "加拿大",
            "zh_TW": "加拿大",
            "en_US": "Canada"
        },
        "code": "CA",
        "ips": "1231080",
        "cities": []
    },
    "Dominican Rep.": {
        "name": {
            "zh_CN": "多米尼加共和国",
            "zh_TW": "多米尼加共和國",
            "en_US": "Dominican Rep."
        },
        "code": "DO",
        "ips": "0",
        "cities": []
    },
    "Panama": {
        "name": {
            "zh_CN": "巴拿马",
            "zh_TW": "巴拿馬",
            "en_US": "Panama"
        },
        "code": "PA",
        "ips": "185280",
        "cities": []
    },
    "Costa Rica": {
        "name": {
            "zh_CN": "哥斯达黎加",
            "zh_TW": "哥斯達黎加",
            "en_US": "Costa Rica"
        },
        "code": "CR",
        "ips": "0",
        "cities": []
    },
    "Guatemala": {
        "name": {
            "zh_CN": "危地马拉",
            "zh_TW": "危地馬拉",
            "en_US": "Guatemala"
        },
        "code": "GT",
        "ips": "0",
        "cities": []
    },
    "Honduras": {
        "name": {
            "zh_CN": "洪都拉斯",
            "zh_TW": "洪都拉斯",
            "en_US": "Honduras"
        },
        "code": "HN",
        "ips": "0",
        "cities": []
    },
    "El Salvador": {
        "name": {
            "zh_CN": "救星",
            "zh_TW": "救星",
            "en_US": "El Salvador"
        },
        "code": "SV",
        "ips": "0",
        "cities": []
    },
    "Nicaragua": {
        "name": {
            "zh_CN": "尼加拉瓜",
            "zh_TW": "尼加拉瓜",
            "en_US": "Nicaragua"
        },
        "code": "NI",
        "ips": "0",
        "cities": []
    },
    "Jamaica": {
        "name": {
            "zh_CN": "牙买加",
            "zh_TW": "牙買加",
            "en_US": "Jamaica"
        },
        "code": "JM",
        "ips": "34240",
        "cities": []
    },
    "Puerto Rico": {
        "name": {
            "zh_CN": "波多黎各",
            "zh_TW": "波多黎各",
            "en_US": "Puerto Rico"
        },
        "code": "PR",
        "ips": "0",
        "cities": []
    },
    "Trinidad and Tobago": {
        "name": {
            "zh_CN": "特立尼达和多巴哥",
            "zh_TW": "特立尼達和多巴哥",
            "en_US": "Trinidad and Tobago"
        },
        "code": "TT",
        "ips": "0",
        "cities": []
    },
    "Barbados": {
        "name": {
            "zh_CN": "巴巴多斯",
            "zh_TW": "巴巴多斯",
            "en_US": "Barbados"
        },
        "code": "BB",
        "ips": "0",
        "cities": []
    },
    "Martinique": {
        "name": {
            "zh_CN": "马提尼克岛",
            "zh_TW": "馬提尼克島",
            "en_US": "Martinique"
        },
        "code": "MQ",
        "ips": "0",
        "cities": []
    },
    "Grenada": {
        "name": {
            "zh_CN": "格林纳达",
            "zh_TW": "格林納達",
            "en_US": "Grenada"
        },
        "code": "GD",
        "ips": "0",
        "cities": []
    },
    "Saint Lucia": {
        "name": {
            "zh_CN": "圣卢西亚",
            "zh_TW": "聖盧西亞",
            "en_US": "Saint Lucia"
        },
        "code": "LC",
        "ips": "1440",
        "cities": []
    },
    "Guadeloupe": {
        "name": {
            "zh_CN": "瓜德罗普岛",
            "zh_TW": "瓜德羅普島",
            "en_US": "Guadeloupe"
        },
        "code": "GP",
        "ips": "0",
        "cities": []
    },
    "Antigua and Barbuda": {
        "name": {
            "zh_CN": "安提瓜和巴布达",
            "zh_TW": "安提瓜和巴布達",
            "en_US": "Antigua and Barbuda"
        },
        "code": "AG",
        "ips": "0",
        "cities": []
    },
    "Bahamas": {
        "name": {
            "zh_CN": "巴哈马",
            "zh_TW": "巴哈馬",
            "en_US": "Bahamas"
        },
        "code": "BS",
        "ips": "0",
        "cities": []
    },
    "Haiti": {
        "name": {
            "zh_CN": "海地",
            "zh_TW": "海地",
            "en_US": "Haiti"
        },
        "code": "HT",
        "ips": "0",
        "cities": []
    },
    "Belize": {
        "name": {
            "zh_CN": "伯利兹",
            "zh_TW": "伯利茲",
            "en_US": "Belize"
        },
        "code": "BZ",
        "ips": "0",
        "cities": []
    },
    "Cuba": {
        "name": {
            "zh_CN": "古巴",
            "zh_TW": "古巴",
            "en_US": "Cuba"
        },
        "code": "CU",
        "ips": "0",
        "cities": []
    },
    "St. Vincent": {
        "name": {
            "zh_CN": "圣文森特",
            "zh_TW": "聖文森特",
            "en_US": "St. Vincent"
        },
        "code": "VC",
        "ips": "0",
        "cities": []
    },
    "Anguilla": {
        "name": {
            "zh_CN": "安圭拉",
            "zh_TW": "安圭拉",
            "en_US": "Anguilla"
        },
        "code": "AI",
        "ips": "0",
        "cities": []
    },
    "Bermuda": {
        "name": {
            "zh_CN": "百慕大",
            "zh_TW": "百慕大",
            "en_US": "Bermuda"
        },
        "code": "BM",
        "ips": "0",
        "cities": []
    },
    "Saint Kitts and Nevis": {
        "name": {
            "zh_CN": "圣基茨和尼维斯",
            "zh_TW": "聖基茨和尼維斯",
            "en_US": "Saint Kitts and Nevis"
        },
        "code": "KN",
        "ips": "0",
        "cities": []
    },
    "Cayman Islands": {
        "name": {
            "zh_CN": "开曼群岛",
            "zh_TW": "開曼羣島",
            "en_US": "Cayman Islands"
        },
        "code": "KY",
        "ips": "0",
        "cities": []
    },
    "Brazil": {
        "name": {
            "zh_CN": "巴西",
            "zh_TW": "巴西",
            "en_US": "Brazil"
        },
        "code": "BR",
        "ips": "47268060",
        "cities": []
    },
    "Ecuador": {
        "name": {
            "zh_CN": "厄瓜多尔",
            "zh_TW": "厄瓜多爾",
            "en_US": "Ecuador"
        },
        "code": "EC",
        "ips": "0",
        "cities": []
    },
    "Argentina": {
        "name": {
            "zh_CN": "阿根廷",
            "zh_TW": "阿根廷",
            "en_US": "Argentina"
        },
        "code": "AR",
        "ips": "1034820",
        "cities": []
    },
    "Colombia": {
        "name": {
            "zh_CN": "哥伦比亚",
            "zh_TW": "哥倫比亞",
            "en_US": "Colombia"
        },
        "code": "CO",
        "ips": "528740",
        "cities": []
    },
    "Uruguay": {
        "name": {
            "zh_CN": "乌拉圭",
            "zh_TW": "烏拉圭",
            "en_US": "Uruguay"
        },
        "code": "UY",
        "ips": "0",
        "cities": []
    },
    "Peru": {
        "name": {
            "zh_CN": "秘鲁",
            "zh_TW": "祕魯",
            "en_US": "Peru"
        },
        "code": "PE",
        "ips": "269440",
        "cities": []
    },
    "Venezuela": {
        "name": {
            "zh_CN": "委内瑞拉",
            "zh_TW": "委內瑞拉",
            "en_US": "Venezuela"
        },
        "code": "VE",
        "ips": "243500",
        "cities": []
    },
    "Chile": {
        "name": {
            "zh_CN": "智利",
            "zh_TW": "智利",
            "en_US": "Chile"
        },
        "code": "CL",
        "ips": "162860",
        "cities": []
    },
    "Bolivia": {
        "name": {
            "zh_CN": "玻利维亚",
            "zh_TW": "玻利維亞",
            "en_US": "Bolivia"
        },
        "code": "BO",
        "ips": "0",
        "cities": []
    },
    "Paraguay": {
        "name": {
            "zh_CN": "巴拉圭",
            "zh_TW": "巴拉圭",
            "en_US": "Paraguay"
        },
        "code": "PY",
        "ips": "0",
        "cities": []
    },
    "Guyana": {
        "name": {
            "zh_CN": "圭亚那",
            "zh_TW": "圭亞那",
            "en_US": "Guyana"
        },
        "code": "GY",
        "ips": "0",
        "cities": []
    },
    "Suriname": {
        "name": {
            "zh_CN": "苏里南",
            "zh_TW": "蘇里南",
            "en_US": "Suriname"
        },
        "code": "SR",
        "ips": "0",
        "cities": []
    },
    "French Guiana": {
        "name": {
            "zh_CN": "法属圭亚那",
            "zh_TW": "法屬圭亞那",
            "en_US": "French Guiana"
        },
        "code": "GF",
        "ips": "0",
        "cities": []
    },
    "Australia": {
        "name": {
            "zh_CN": "澳大利亚",
            "zh_TW": "澳大利亞",
            "en_US": "Australia"
        },
        "code": "AU",
        "ips": "101440",
        "cities": []
    },
    "New Zealand": {
        "name": {
            "zh_CN": "新西兰",
            "zh_TW": "新西蘭",
            "en_US": "New Zealand"
        },
        "code": "NZ",
        "ips": "22220",
        "cities": []
    },
    "Fiji": {
        "name": {
            "zh_CN": "斐济",
            "zh_TW": "斐濟",
            "en_US": "Fiji"
        },
        "code": "FJ",
        "ips": "0",
        "cities": []
    },
    "Guam": {
        "name": {
            "zh_CN": "关岛",
            "zh_TW": "關島",
            "en_US": "Guam"
        },
        "code": "GU",
        "ips": "0",
        "cities": []
    },
    "New Caledonia": {
        "name": {
            "zh_CN": "新喀里多尼亚",
            "zh_TW": "新喀里多尼亞",
            "en_US": "New Caledonia"
        },
        "code": "NC",
        "ips": "0",
        "cities": []
    },
    "South Africa": {
        "name": {
            "zh_CN": "南非",
            "zh_TW": "南非",
            "en_US": "South Africa"
        },
        "code": "ZA",
        "ips": "700200",
        "cities": []
    },
    "Morocco": {
        "name": {
            "zh_CN": "摩洛哥",
            "zh_TW": "摩洛哥",
            "en_US": "Morocco"
        },
        "code": "MA",
        "ips": "0",
        "cities": []
    },
    "Tunisia": {
        "name": {
            "zh_CN": "突尼斯",
            "zh_TW": "突尼斯",
            "en_US": "Tunisia"
        },
        "code": "TN",
        "ips": "0",
        "cities": []
    },
    "Algeria": {
        "name": {
            "zh_CN": "阿尔及利亚",
            "zh_TW": "阿爾及利亞",
            "en_US": "Algeria"
        },
        "code": "DZ",
        "ips": "94320",
        "cities": []
    },
    "Kenya": {
        "name": {
            "zh_CN": "肯尼亚",
            "zh_TW": "肯尼亞",
            "en_US": "Kenya"
        },
        "code": "KE",
        "ips": "91580",
        "cities": []
    },
    "Egypt": {
        "name": {
            "zh_CN": "埃及",
            "zh_TW": "埃及",
            "en_US": "Egypt"
        },
        "code": "EG",
        "ips": "0",
        "cities": []
    },
    "Senegal": {
        "name": {
            "zh_CN": "塞内加尔",
            "zh_TW": "塞內加爾",
            "en_US": "Senegal"
        },
        "code": "SN",
        "ips": "0",
        "cities": []
    },
    "Mali": {
        "name": {
            "zh_CN": "马里",
            "zh_TW": "馬利",
            "en_US": "Mali"
        },
        "code": "ML",
        "ips": "0",
        "cities": []
    },
    "Ivory Coast": {
        "name": {
            "zh_CN": "科特迪瓦",
            "zh_TW": "科特迪瓦",
            "en_US": "Ivory Coast"
        },
        "code": "CI",
        "ips": "0",
        "cities": []
    },
    "Gabon": {
        "name": {
            "zh_CN": "加蓬",
            "zh_TW": "加蓬",
            "en_US": "Gabon"
        },
        "code": "GA",
        "ips": "0",
        "cities": []
    },
    "Congo Republic": {
        "name": {
            "zh_CN": "刚果共和国",
            "zh_TW": "剛果共和國",
            "en_US": "Congo Republic"
        },
        "code": "CG",
        "ips": "0",
        "cities": []
    },
    "Botswana": {
        "name": {
            "zh_CN": "博茨瓦纳",
            "zh_TW": "博茨瓦納",
            "en_US": "Botswana"
        },
        "code": "BW",
        "ips": "0",
        "cities": []
    },
    "Angola": {
        "name": {
            "zh_CN": "安哥拉",
            "zh_TW": "安哥拉",
            "en_US": "Angola"
        },
        "code": "AO",
        "ips": "0",
        "cities": []
    },
    "Togo": {
        "name": {
            "zh_CN": "多哥",
            "zh_TW": "多哥",
            "en_US": "Togo"
        },
        "code": "TG",
        "ips": "0",
        "cities": []
    },
    "Mauritius": {
        "name": {
            "zh_CN": "毛里求斯",
            "zh_TW": "毛里求斯",
            "en_US": "Mauritius"
        },
        "code": "MU",
        "ips": "0",
        "cities": []
    },
    "Nigeria": {
        "name": {
            "zh_CN": "尼日利亚",
            "zh_TW": "尼日利亞",
            "en_US": "Nigeria"
        },
        "code": "NG",
        "ips": "8600",
        "cities": []
    },
    "Reunion": {
        "name": {
            "zh_CN": "留尼汪",
            "zh_TW": "留尼汪",
            "en_US": "Reunion"
        },
        "code": "RE",
        "ips": "0",
        "cities": []
    },
    "Burkina Faso": {
        "name": {
            "zh_CN": "布基纳法索",
            "zh_TW": "布基納法索",
            "en_US": "Burkina Faso"
        },
        "code": "BF",
        "ips": "0",
        "cities": []
    },
    "Libya": {
        "name": {
            "zh_CN": "利比亚",
            "zh_TW": "利比亞",
            "en_US": "Libya"
        },
        "code": "LY",
        "ips": "0",
        "cities": []
    },
    "Mozambique": {
        "name": {
            "zh_CN": "莫桑比克",
            "zh_TW": "莫桑比克",
            "en_US": "Mozambique"
        },
        "code": "MZ",
        "ips": "3160",
        "cities": []
    },
    "Cameroon": {
        "name": {
            "zh_CN": "喀麦隆",
            "zh_TW": "喀麥隆",
            "en_US": "Cameroon"
        },
        "code": "CM",
        "ips": "0",
        "cities": []
    },
    "Zimbabwe": {
        "name": {
            "zh_CN": "津巴布韦",
            "zh_TW": "津巴布韋",
            "en_US": "Zimbabwe"
        },
        "code": "ZW",
        "ips": "0",
        "cities": []
    },
    "Ghana": {
        "name": {
            "zh_CN": "加纳",
            "zh_TW": "加納",
            "en_US": "Ghana"
        },
        "code": "GH",
        "ips": "1640",
        "cities": []
    },
    "Madagascar": {
        "name": {
            "zh_CN": "马达加斯加",
            "zh_TW": "馬達加斯加",
            "en_US": "Madagascar"
        },
        "code": "MG",
        "ips": "1480",
        "cities": []
    },
    "Benin": {
        "name": {
            "zh_CN": "贝宁",
            "zh_TW": "貝寧",
            "en_US": "Benin"
        },
        "code": "BJ",
        "ips": "0",
        "cities": []
    },
    "Ethiopia": {
        "name": {
            "zh_CN": "埃塞俄比亚",
            "zh_TW": "埃塞俄比亞",
            "en_US": "Ethiopia"
        },
        "code": "ET",
        "ips": "1382",
        "cities": []
    },
    "DR Congo": {
        "name": {
            "zh_CN": "刚果民主共和国",
            "zh_TW": "剛果民主共和國",
            "en_US": "DR Congo"
        },
        "code": "CD",
        "ips": "0",
        "cities": []
    },
    "Cape Verde": {
        "name": {
            "zh_CN": "佛得角",
            "zh_TW": "佛得角",
            "en_US": "Cape Verde"
        },
        "code": "CV",
        "ips": "0",
        "cities": []
    },
    "Uganda": {
        "name": {
            "zh_CN": "乌干达",
            "zh_TW": "烏干達",
            "en_US": "Uganda"
        },
        "code": "UG",
        "ips": "0",
        "cities": []
    },
    "Gambia": {
        "name": {
            "zh_CN": "冈比亚",
            "zh_TW": "岡比亞",
            "en_US": "Gambia"
        },
        "code": "GM",
        "ips": "0",
        "cities": []
    },
    "Tanzania": {
        "name": {
            "zh_CN": "坦桑尼亚",
            "zh_TW": "坦桑尼亞",
            "en_US": "Tanzania"
        },
        "code": "TZ",
        "ips": "0",
        "cities": []
    },
    "Namibia": {
        "name": {
            "zh_CN": "纳米比亚",
            "zh_TW": "納米比亞",
            "en_US": "Namibia"
        },
        "code": "NA",
        "ips": "0",
        "cities": []
    },
    "Somalia": {
        "name": {
            "zh_CN": "索马里",
            "zh_TW": "索馬里",
            "en_US": "Somalia"
        },
        "code": "SO",
        "ips": "0",
        "cities": []
    },
    "Zambia": {
        "name": {
            "zh_CN": "赞比亚",
            "zh_TW": "贊比亞",
            "en_US": "Zambia"
        },
        "code": "ZM",
        "ips": "0",
        "cities": []
    },
    "Guinea": {
        "name": {
            "zh_CN": "几内亚",
            "zh_TW": "幾內亞",
            "en_US": "Guinea"
        },
        "code": "GN",
        "ips": "0",
        "cities": []
    },
    "Sao Tome and Principe": {
        "name": {
            "zh_CN": "圣多美和普林西比",
            "zh_TW": "聖多美和普林西比",
            "en_US": "Sao Tome and Principe"
        },
        "code": "ST",
        "ips": "0",
        "cities": []
    },
    "Rwanda": {
        "name": {
            "zh_CN": "卢旺达",
            "zh_TW": "盧旺達",
            "en_US": "Rwanda"
        },
        "code": "RW",
        "ips": "0",
        "cities": []
    },
    "Seychelles": {
        "name": {
            "zh_CN": "塞舌尔",
            "zh_TW": "塞舌爾",
            "en_US": "Seychelles"
        },
        "code": "SC",
        "ips": "0",
        "cities": []
    },
    "Mayotte": {
        "name": {
            "zh_CN": "马约特岛",
            "zh_TW": "馬約特島",
            "en_US": "Mayotte"
        },
        "code": "YT",
        "ips": "0",
        "cities": []
    },
    "Malawi": {
        "name": {
            "zh_CN": "马拉维",
            "zh_TW": "馬拉維",
            "en_US": "Malawi"
        },
        "code": "MW",
        "ips": "0",
        "cities": []
    },
    "Djibouti": {
        "name": {
            "zh_CN": "吉布提",
            "zh_TW": "吉布提",
            "en_US": "Djibouti"
        },
        "code": "DJ",
        "ips": "0",
        "cities": []
    },
    "Sudan": {
        "name": {
            "zh_CN": "苏丹",
            "zh_TW": "蘇丹",
            "en_US": "Sudan"
        },
        "code": "SD",
        "ips": "0",
        "cities": []
    }
}

// 底部-热门国家：美国 加拿大 新加坡 澳大利亚 英国 日本 马来西亚
export const FooterHotRegions = ["US", "CA", "SG", "AU", "GB", "JP", "MY"];

// 首页-热门国家：美国 英国 加拿大 法国 德国 日本 澳大利亚 新加坡
export const IndexHotRegions = ["US", "GB", "CA", "FR", "DE", "JP", "AU", "SG"];

// 动态住宅-热门国家：美国 巴西 印度 加拿大 德国 日本 韩国 澳大利亚
export const ResidentHotRegions = ["US", "BR", "IN", "CA", "DE", "JP", "KR", "AU"];

// 静态住宅-热门国家：美国 英国 加拿大 法国 德国 日本 澳大利亚 新加坡
export const IspHotRegions = ["US", "GB", "CA", "FR", "DE", "JP", "AU", "SG"];

// 静态数据中心-热门国家：美国 德国 英国 法国 加拿大 日本 韩国 新加坡
export const DcHotRegions = ["US", "DE", "GB", "FR", "CA", "JP", "KR", "SG"];

// 所有地区-热门国家：美国 英国 日本 德国 法国 新加坡 加拿大 澳大利亚 韩国 印度 印度尼西亚 西班牙 意大利 马来西亚 越南
export const LocationsHotRegions = ["US", "GB", "JP", "DE", "FR", "SG", "CA", "AU", "KR", "IN", "ID", "ES", "IT", "MY", "VN"];
