import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import Layout from '@/application/Layout';
import Home from '@/application/Home';
import Page404 from '@/application/Page404';
import PageLoading from '@/baseUI/PageLoading';
const IndexComponent = lazy(() => import('@/application/Index'));

const OverseasLiveComponent = lazy(() => import('@/application/OverseasLive'));
const CrossBorderECommerceComponent = lazy(() => import('@/application/CrossBorderECommerce'));
const SocialMediaOperationComponent = lazy(() => import('@/application/SocialMediaOperation'));
const TikTokComponent = lazy(() => import('@/application/TikTok'));
const AccountMatrixComponent = lazy(() => import('@/application/AccountMatrix'));
const CrossBorderOfficeComponent = lazy(() => import('@/application/CrossBorderOffice'));
const GameComponent = lazy(() => import('@/application/Game'));

const AgentPlanComponent = lazy(() => import('@/application/AgentPlan'));
const ServicePartnersComponent = lazy(() => import('@/application/ServicePartners'));

const LocationProxyComponent = lazy(() => import('@/application/LocationProxy'));
const CountryProxyComponent = lazy(() => import('@/application/CountryProxy'));

const FAQComponent = lazy(() => import('@/application/FAQ'));
const ToolComponent = lazy(() => import('@/application/Tools'));

const SdwanNetworkComponent = lazy(() => import('@/application/SdwanNetwork'));
const SdwanAppComponent = lazy(() => import('@/application/SdwanApp'));

const PdfPageComponent = lazy(() => import('@/application/PdfPage'));
const CustomerManagerComponent = lazy(() => import('@/application/ActivityPages/CustomerManager'));

const SuspenseComponent = Component => props => {
    return (
        <Suspense fallback={<PageLoading />}>
            <Component {...props}></Component>
        </Suspense>
    )
}

const navRoutes = [
    {
        path: '/index',
        component: SuspenseComponent(IndexComponent),
        pageTitle: 'XINGLOO-跨境电商加速盒_专线网络_海外直播_住宅代理服务_星途时动科技',
        pageKeywords: '跨境电商直播加速, 星鹿加速盒, 跨境电商解决方案, 跨境电商网络优化',
        pageDescription: '星鹿跨境电商加速盒，全球节点覆盖，为跨境电商提供一站式解决方案，包括网络优化、直播加速等服务，助力跨境电商业务快速发展。'
    },
    {
        path: '/overseas-live',
        component: SuspenseComponent(OverseasLiveComponent),
        pageTitle: '海外直播解决方案 | 星鹿跨境电商加速盒',
        pageKeywords: '海外直播,Tiktok直播,跨境电商,网络加速,高清直播,智选直播参数,独享IP,网络安全防护',
        pageDescription: '星鹿跨境电商加速盒为海外直播提供高效、稳定的网络解决方案，优化传输路径，确保流畅的直播体验。'
    },
    {
        path: '/e-commerce',
        component: SuspenseComponent(CrossBorderECommerceComponent),
        pageTitle: '跨境电商解决方案 | 提升全球运营效率',
        pageKeywords: '跨境电商加速, 跨境电商解决方案, 星鹿加速盒, 电商平台访问加速, 跨境订单处理优化, 跨境电商网络支撑',
        pageDescription: '星鹿跨境电商加速盒提供稳定高效的海外直播解决方案，优化网络传输，确保流畅体验，助力跨境业务。'
    },
    {
        path: '/social-media',
        component: SuspenseComponent(SocialMediaOperationComponent),
        pageTitle: '星鹿跨境电商社媒运营解决方案',
        pageKeywords: '社媒运营,跨境电商,全球IP覆盖,智能网络优化,平台账号管理,数据传输加密,用户需求',
        pageDescription: '探索星鹿社媒运营解决方案，突破IP限制，确保内容快速加载，提升品牌影响力与销售效果。'
    },
    {
        path: '/tiktok',
        component: SuspenseComponent(TikTokComponent),
        pageTitle: 'Tiktok直播专线-星鹿跨境电商加速盒',
        pageKeywords: '高速稳定, 社交媒体加速, 星鹿加速盒, 星鹿跨境电商加速, 社媒运营网络加速, 海外社交媒体推广加速, Facebook 加速, Instagram 加速, Twitter 加速',
        pageDescription: '星鹿跨境电商加速盒助力跨境社媒运营，快速发布内容、精准互动，消除网络阻碍，让您在 Facebook、Instagram、Twitter 等海外社交媒体平台游刃有余，深度触达全球目标受众。'
    },
    {
        path: '/account-matrix',
        component: SuspenseComponent(AccountMatrixComponent),
        pageTitle: '星鹿跨境电商账号矩阵解决方案',
        pageKeywords: '账号矩阵,IP资源管理,网络稳定性,跨境电商,多平台整合,账号运营,全球网络覆盖',
        pageDescription: '星鹿跨境电商提供专业账号矩阵解决方案，确保稳定IP资源与高效运营，助力企业在多平台营销中脱颖而出。'
    },
    {
        path: '/office',
        component: SuspenseComponent(CrossBorderOfficeComponent),
        pageTitle: '跨境办公解决方案 | 星鹿电商加速盒',
        pageKeywords: '跨境办公, 网络加速, 数据传输, 全球IP资源, 网络管理, 网络安全, 业务协作, 一站式解决方案',
        pageDescription: '星鹿跨境电商加速盒提供高效稳定的跨境办公解决方案，优化网络连接，确保安全可靠的数据传输。'
    },
    {
        path: '/game',
        component: SuspenseComponent(GameComponent),
        pageTitle: '全球游戏网络解决方案-星鹿电商加速盒',
        pageKeywords: '游戏解决方案, 跨境电商, 网络延迟, 电子竞技, 游戏直播, 网络稳定性, DDoS攻击防护, 全球网络覆盖',
        pageDescription: '星鹿跨境电商加速盒为在线游戏提供低延迟、高稳定性网络解决方案，助力全球玩家畅享无缝游戏体验。'
    },
    {
        path: '/agent-plan',
        component: SuspenseComponent(AgentPlanComponent),
        pageTitle: '加入星鹿代理商计划，轻松赚返利',
        pageKeywords: '代理商计划, 星鹿跨境电商, 无门槛代理, 技术支持, 市场开拓',
        pageDescription: '加入星鹿代理商计划，享受零费用启动，丰厚返利与专业支持，共同拓展跨境电商市场，快速盈利！'
    },
    {
        path: '/partners',
        component: SuspenseComponent(ServicePartnersComponent),
        pageTitle: '合作伙伴-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒合作伙伴, 跨境业务合作伙伴, 星鹿跨境电商加速, 合作伙伴招募, 跨境电商合作项目, 战略合作伙伴, 合作共赢跨境电商',
        pageDescription: '在此了解星鹿跨境电商加速盒的合作伙伴生态，诚邀各界有志之士加入，凭借卓越网络加速产品，协同发力，深挖跨境电商潜力市场。'
    },
    {
        path: '/regions',
        component: Layout,
        routes: [{
            path: '/regions',
            exact: true,
            component: SuspenseComponent(LocationProxyComponent),
            pageTitle: {
                "zh_CN": "星鹿跨境电商 | 全球更多地区，拓展跨境新边界",
                "zh_TW": "星鹿跨國電商 | 全球更多地區，拓展跨國新邊界",
                "en_US": "Global Proxy IP Popular Xinglu Cross-border E-commerce | Expanding new cross-border boundaries in more regions around the world and Regions - IPFLY "
            },
            pageKeywords: {
                "zh_CN": "星鹿跨境电商、全球更多地区、跨境新边界、本地化服务、高效物流、跨境商机",
                "zh_TW": "星鹿跨境電商、全球更多地區、跨境新邊界、在地化服務、高效率物流、跨境商機",
                "en_US": "Xinglu cross-border e-commerce, more regions around the world, new cross-border boundaries, localized services, efficient logistics, cross-border business opportunities"
            },
            pageDescription: {
                "zh_CN": "星鹿跨境电商，助力卖家拓展全球更多地区，覆盖欧美、亚洲、南美洲等新兴市场。凭借本地化服务、高效物流和强大的技术支持，星鹿让跨境生意更简单、更高效。立即加入，开启全球商机之旅！",
                "zh_TW": "星鹿跨境電商，協助賣家拓展全球更多地區，涵蓋歐美、亞洲、南美洲等新興市場。憑藉在地化服務、高效物流和強大的技術支持，星鹿讓跨境生意更簡單、更有效率。立即加入，開啟全球商機之旅！",
                "en_US": "Xinglu Cross-border E-commerce helps sellers expand into more regions around the world, covering emerging markets such as Europe, America, Asia, and South America. With localized services, efficient logistics, and strong technical support, Xinglu makes cross-border business simpler and more efficient. Join now and start your journey of global business opportunities!"
            },
            auth: true,
        }, {
            path: '/regions/:id',
            component: SuspenseComponent(CountryProxyComponent),
            auth: true,
        }]
    },
    {
        path: '/faq',
        component: SuspenseComponent(FAQComponent),
        pageTitle: '常见问题-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒常见问题, 跨境电商加速盒问题解答, FAQ, 常见问题解答, 网络加速疑问, 跨境加速盒使用问题',
        pageDescription: '汇总星鹿跨境电商加速盒用户关心的各类常见问题，涵盖产品功能、安装使用、故障排查等多方面，一一给予清晰、权威解答，助您顺畅使用加速盒。'
    },
    {
        path: '/tool',
        component: SuspenseComponent(ToolComponent),
        pageTitle: 'IP地址在线查询工具 - 实时查找您的IP信息',
        pageKeywords: '在线IP查询工具,免费IP查询, 地址查询, IP定位, 在线工具, IP地址查找, 网络工具, IP信息查询, 实时IP查询',
        pageDescription: 'IP地址在线查询工具为您提供便捷的IP地址定位服务。通过输入IP地址，您可以快速获取该IP的地理位置、ISP信息及其他相关数据，帮助您更好地理解网络连接和用户来源。'
    },
    {
        path: '/sdwan-network',
        component: SuspenseComponent(SdwanNetworkComponent),
        pageTitle: 'SD-WAN混合组网：灵活稳定的网络解决方案',
        pageKeywords: '星鹿 SD - WAN, 混合组网, 网络融合, SD - WAN 产品优势, 多链路协同',
        pageDescription: '星鹿 SD - WAN 混合组网产品，灵活整合多种网络链路，智能调配资源，为企业打造高可靠、高性能的定制化网络架构，满足复杂业务场景下的多元网络需求。'
    },
    {
        path: '/sdwan-app',
        component: SuspenseComponent(SdwanAppComponent),
        pageTitle: 'SD-WAN应用加速 ： 提升跨境电商效率',
        pageKeywords: '星鹿 SD - WAN, 应用加速, 企业应用优化, SD - WAN 加速技术, 业务流程提速',
        pageDescription: '星鹿 SD - WAN 应用加速产品聚焦企业关键应用，运用前沿加速技术削减延迟、提升带宽利用率，确保 ERP、CRM、视频会议等应用流畅运转，赋能企业业务创新发展。'
    },
]

const routes = [
    {
        path: '/404',
        component: Page404,
    },
    {
        path: '/product',
        component: PdfPageComponent,
        pageTitle: '星鹿加速盒使用手册-星鹿跨境电商加速盒',
        pageKeywords: '星鹿加速盒使用手册, 加速盒操作指南, 产品操作指南, 安装步骤详解, 功能使用说明',
        pageDescription: '星鹿跨境电商加速盒使用手册，图文并茂、步骤清晰，全方位指导用户精准掌握使用技巧，充分发挥产品效能。'
    },
    {
        path: '/customer-manager',
        component: CustomerManagerComponent,
    },
    {
        path: '/',
        component: Home,
        routes: [{
            path: '/',
            exact: true,
            render: () => ( <Redirect to={'/index'} /> )
        },
        ...navRoutes,
        {
            path: '*',
            render: () => ( <Redirect to={'/404'} /> ),
        }]
    }
]

export default routes;
