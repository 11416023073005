import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowDown } from '@/assets/svg/arrowDown.svg';
import logoPic from '@/assets/png/logo_m.png';
import NavComponent from '../NavComponents';
import { MenuItems } from '@/routes/menu';
import './style.less';
import { openIpflyPath, openMainClientPath } from '@/utils/config';

function SiderNav(props) {
    const { history, pathname, search, visible } = props;
    const { onChange } = props;
    const [currentExpend, setCurrentExpend] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        onChange(false);
        // eslint-disable-next-line
    }, [pathname, search])

    useEffect(() => {
        if (!visible) {
            setCurrentExpend('');
        }
    }, [visible])

    const onClose = () => {
        onChange(false);
    }

    const clickMenu = (item, index) => {
        setCurrentIndex(index);
        if (currentExpend === item.key) {
            setCurrentExpend('');
        } else {
            setCurrentExpend(item.key);
        }
        if (!item.hasArrow && item.path) {
            history.push(item.path);
        } else if (!item.hasArrow && item.key === 'staticIP') {
            openIpflyPath();
        }
    }

    const hidePanel = () => {
        setCurrentExpend('');
    }

    return <Drawer
        className="sider-nav-wrap"
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        width="100%"
    >
        <div className="sider-nav-header">
            <div className="logo" onClick={() => history.push('/')}>
                <img src={logoPic} width="100%" alt="logo" />
            </div>
            <div className="btns">
                <CloseOutlined className="close-btn" onClick={onClose} />
            </div>
        </div>
        <div className="sider-nav-list">
            {
                MenuItems.map((item, index) => {
                    return <div key={item.key}>
                        <div className={currentExpend===item.key?"menu-item expend":"menu-item"} onClick={() => clickMenu(item, index)}>
                            {item.label}
                            {item.hasArrow ? <ArrowDown className="arrow-svg-icon" /> : null}
                        </div>
                        {
                            currentExpend === item.key ? <NavComponent
                                inline={true}
                                history={history}
                                current={currentExpend}
                                showSubNav={true}
                                currentIndex={currentIndex}
                                hidePanel={hidePanel}
                            /> : null
                        }
                    </div>
                })
            }
        </div>
        <div className="sider-nav-bottom">
            <div className="cs-btn-plain-white login-btn" onClick={() => openMainClientPath('/login')}>登录</div>
            <div className="cs-btn-primary sighup-btn" onClick={() => openMainClientPath('/register')}>注册</div>
        </div>
    </Drawer>
}

export default React.memo(SiderNav);