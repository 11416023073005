import React from "react";
import SolutionComp from "./solution";
import HelpComp from "./help";
import ProductComp from "./product";
import AgentComp from "./agent";
import ToolComp from "./tool";

function NavComp(props) {
  const { current } = props;

  const getComp = (props) => {
    switch (current) {
      case "solution":
        return <SolutionComp {...props} />;
      case "agent":
        return <AgentComp {...props} />;
      case "help":
        return <HelpComp {...props} />;
      case "tool":
        return <ToolComp {...props} />;
      case "product":
        return <ProductComp {...props} />;
      default:
        break;
    }
  };

  return <>{getComp(props)}</>;
}

export default React.memo(NavComp);
