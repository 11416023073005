import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Help1Pic } from '@/assets/svg/nav/help1.svg';
import { ReactComponent as Help2Pic } from '@/assets/svg/nav/help2.svg';
import { ReactComponent as Help3Pic } from '@/assets/svg/nav/help3.svg';
import { ReactComponent as Help4Pic } from '@/assets/svg/nav/help4.svg';
import { openXinglooBoxPath, openXinglooDocPath, openXinglooNewsPath } from '@/utils/config';

const Container = styled.div`
    position: absolute;
    left: ${props => `${-238 + (props.index * 128 + 176)}px`};
    top: 51px;
    width: 415px;
    min-height: 323px;
    background: #FFFFFF;
    color: #0C1939;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    border-radius: 12px 12px 12px 12px;
    z-index: 2;
    .main-wrap {
        padding: 20px 28px;
        .title {
            font-size: 14px;
        }
        .nav-list-wrap {
            .nav-item {
                color: #0C1939;
                width: 100%;
                padding: 10px 0 10px 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background-color: #F2F8FE;
                    border-radius: 4px
                }
                .nav-icon {
                    flex-shrink: 0;
                    width: 44px;
                    height: 44px;
                    margin-right: 16px;
                }
                .nav-cont {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .nav-title {
                        font-family: 'SourceHanSansCN-Medium';
                    }
                    .nav-desc {
                        font-size: 12px;
                        color: #0B1B32;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
    &.sub-nav-picker-inline {
        position: static;
        width: 100%;
        min-height: auto;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        .main-wrap {
            padding: 16px 0 0 0;
            border-top: 1px solid rgba(0,0,0,0.05);
            .title {
                color: #989FAA;
                font-family: 'SourceHanSansCN-Medium';
            }
            .nav-list-wrap {
                .nav-item {
                    margin: 16px 0;
                    padding: 0;
                    .nav-icon {
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
                    }
                    .nav-cont {
                        .nav-title {
                            font-size: 12px;
                        }
                        .nav-desc {
                            display: none;
                        }
                    }
                }
            }
        }
    }
`

function Comp(props) {
    const { history, currentIndex, inline, showSubNav } = props;
    return <Container className={`${
        inline ? "sub-nav-picker sub-nav-picker-inline" : "sub-nav-picker"
      } ${showSubNav ? "show-nav" : "hidden-nav"}`} index={currentIndex}>
        <div className='main-wrap'>
            <div className='title'>帮助中心</div>
            <div className='nav-list-wrap'>
                <a className='nav-item' href='/faq'>
                    <Help1Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>常见问题</div>
                        <div className='nav-desc'>查找常见问题的解答</div>
                    </div>
                </a>
                <div className='nav-item' onClick={() => openXinglooBoxPath()}>
                    <Help2Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>星鹿加速盒使用手册</div>
                        <div className='nav-desc'>让您快速上手星鹿加速盒</div>
                    </div>
                </div>
                <div className='nav-item' onClick={() => openXinglooNewsPath()}>
                    <Help3Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>新闻资讯</div>
                        <div className='nav-desc'>定期与您分享行业资讯及干货知识</div>
                    </div>
                </div>
                <div className='nav-item' onClick={() => openXinglooDocPath()}>
                    <Help4Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>星鹿帮助文档</div>
                        <div className='nav-desc'>帮助您了解星鹿产品使用</div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default React.memo(Comp);