import React from "react";
import styled from "styled-components";
import { ReactComponent as Agent1Pic } from "@/assets/svg/nav/agent1.svg";
import { ReactComponent as Agent2Pic } from "@/assets/svg/nav/agent2.svg";

const Container = styled.div`
  position: absolute;
  left: ${(props) => `${-238 + (props.index * 128 + 176)}px`};
  top: 51px;
  width: 415px;
  background: #ffffff;
  color: #0c1939;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 12px 12px 12px 12px;
  z-index: 2;
  .main-wrap {
    padding: 20px 28px;
    .title {
      font-size: 14px;
    }
    .nav-list-wrap {
      .nav-item {
        color: #0c1939;
        width: 100%;
        padding: 10px 0 10px 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #f2f8fe;
          border-radius: 4px;
        }
        .nav-icon {
          flex-shrink: 0;
          width: 44px;
          height: 44px;
          margin-right: 16px;
        }
        .nav-cont {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .nav-title {
            font-family: "SourceHanSansCN-Medium";
          }
          .nav-desc {
            font-size: 12px;
            color: #0b1b32;
            margin-top: 4px;
          }
        }
      }
    }
  }
  &.sub-nav-picker-inline {
    position: static;
    width: 100%;
    min-height: auto;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    .main-wrap {
      padding: 16px 0 0 0;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      .title {
        color: #989faa;
        font-family: "SourceHanSansCN-Medium";
      }
      .nav-list-wrap {
        .nav-item {
          margin: 16px 0;
          padding: 0;
          .nav-icon {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }
          .nav-cont {
            .nav-title {
              font-size: 12px;
            }
            .nav-desc {
              display: none;
            }
          }
        }
      }
    }
  }
`;

function Comp(props) {
  const { history, currentIndex, inline, showSubNav } = props;
  return (
    <Container
      className={`${
        inline ? "sub-nav-picker sub-nav-picker-inline" : "sub-nav-picker"
      } ${showSubNav ? "show-nav" : "hidden-nav"}`}
      index={currentIndex}
    >
      <div className="main-wrap">
        <div className="title">代理商计划</div>
        <div className="nav-list-wrap">
          <a className="nav-item" href="/agent-plan">
            <Agent1Pic className="nav-icon" />
            <div className="nav-cont">
              <div className="nav-title">代理商计划</div>
              <div className="nav-desc">专线有加速，减担不简单</div>
            </div>
          </a>
          <a className="nav-item" href="/partners">
            <Agent2Pic className="nav-icon" />
            <div className="nav-cont">
              <div className="nav-title">合作伙伴</div>
              <div className="nav-desc">携手并进，相得益彰</div>
            </div>
          </a>
        </div>
      </div>
    </Container>
  );
}

export default React.memo(Comp);
