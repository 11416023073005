import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import logoPic from "@/assets/png/logo_w.png";
// import { ReactComponent as WechatPic } from './images/wechat.svg';
// import { ReactComponent as BilibiliPic } from './images/bilibili.svg';
// import { ReactComponent as TiktokPic } from './images/tiktok.svg';
import wepayPic from "./images/wepay.png";
import alipayPic from "./images/alipay.png";
import {
  AppDataByDomain,
  openXinglooBoxPath,
  openXinglooDocPath,
  openXinglooNewsPath,
  openMail,
} from "@/utils/config";
import beianPic from "@/assets/png/beian.png";
import lx1Pic from "./images/lx1Pic.png";
import lx2Pic from "./images/lx2Pic.png";
import lx3Pic from "./images/lx3Pic.png";
import lx4Pic from "./images/lx4Pic.png";
import gzh_foot from "./images/gzh_foot.jpg";
import { FooterHotRegions, CountriesList } from "@/utils/locations";

const Container = styled.div`
  width: 100%;
  background: #0c1939;
  position: relative;
  .xl-footer__container {
    width: 100%;
    max-width: 1600px;
    padding: 0 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .xl-footer__company-header {
    padding: 80px 0 24px 0;
    display: flex;
    justify-content: space-between;
  }
  .xl-footer__company-header-left {
    .xl-footer__company-logo {
      width: 168px;
      height: auto;
    }
    .xl-footer__company-title {
      font-family: "SourceHanSansCN-Medium";
      font-size: 16px;
      color: #9591a2;
      line-height: 24px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;
    }
    .xl-footer__company-contract {
      font-family: "SourceHanSansCN-Medium";
      color: #9591a2;
      line-height: 16px;
      margin-top: 20px;
      .xl-footer__company-email {
        font-family: "SourceHanSansCN";
        color: #ffffff;
        cursor: pointer;
      }
    }
    .xl-footer__company-desc {
      color: #e1e0e0;
      line-height: 24px;
      margin-top: 24px;
      p {
        margin-bottom: 0;
      }
    }
  }
  .xl-footer__company-header-right {
    display: flex;
    flex-wrap: wrap;
    .nav-wrap {
      min-width: 130px;
      margin-left: 50px;
      margin-bottom: 20px;
      color: #6f7b98;
      .nav-item {
        color: #ffffff;
        margin-top: 16px;
        cursor: pointer;
        &:hover {
          color: #6f7b98;
        }
        .item-title {
          color: #ffffff;
          &:hover {
            color: #6f7b98;
          }
        }
      }
    }
  }
  .xl-footer__company-bottom {
    width: 100%;
    height: 118px;
    border-top: 1px solid #254076;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .xl-footer__company-pay {
      height: 100%;
      display: flex;
      align-items: center;
      .xl-footer__company-pay-icon {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
    }
  }
  .xl-footer__company-contract {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .contract-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
  .gzh_foot {
    position: absolute;
    bottom: 260px;
  }
  .xl-footer__company-pay {
    margin-top: 24px;
    .xl-footer__company-pay-item {
      width: 40px;
      height: 40px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .xl-footer__copyright {
    color: #e1e0e0;
    display: flex;
    flex-wrap: wrap;
    .xl-footer__network-button {
      margin-left: 20px;
      color: #e1e0e0;
      display: flex;
      align-items: center;
      .xl-footer__network-button-img {
        width: 16px;
        height: auto;
        margin-right: 4px;
      }
    }
  }
  @media (max-width: 768px) {
    .xl-footer__company-header {
      padding: 28px 0 24px 0;
      flex-wrap: wrap;
    }
    .xl-footer__company-header-left {
      order: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .xl-footer__company-logo {
        width: 151px;
        margin-top: 18px;
        margin-bottom: 12px;
      }
      .xl-footer__copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        .xl-footer__network-button {
          margin-left: 0;
          margin-top: 8px;
          font-size: 12px;
          .xl-footer__network-button-img {
            width: 12px;
            height: auto;
            margin-right: 2px;
          }
        }
      }
    }
    .xl-footer__company-header-right {
      order: 1;
      width: 100%;
      justify-content: space-around;
      .nav-wrap {
        min-width: 100px;
        margin-left: 0;
      }
    }
    .xl-footer__company-bottom {
      height: 84px;
      .xl-footer__company-contract {
        margin-top: 0;
      }
      .xl-footer__company-pay {
        margin-top: 0;
        .xl-footer__company-pay-item {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        .xl-footer__company-pay-icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media (max-width: 375px) {
    .xl-footer__company-header-right {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      height: 635px;
      .nav-wrap {
        /* min-width: 130px; */
        position: relative;
        width: calc(100% / 2);
        box-sizing: border-box;
      }
    }
  }
`;

// const SocialMedia = () => {
//     return <div className="xl-footer__company-contract">
//         <WechatPic className="contract-icon" />
//         <BilibiliPic className="contract-icon" />
//         <TiktokPic className="contract-icon" />
//     </div>
// }

const staticMenuList = [
  {
    title: "解决方案",
    children: [
      {
        title: "海外直播",
        url: "/overseas-live",
      },
      {
        title: "跨境电商",
        url: "/e-commerce",
      },
      {
        title: "社媒运营",
        url: "/social-media",
      },
      {
        title: "账号矩阵",
        url: "/account-matrix",
      },
      {
        title: "跨境办公",
        url: "/office",
      },
      {
        title: "游戏",
        url: "/game",
      },
    ],
  },
  {
    title: "帮助中心",
    children: [
      {
        title: "FAQ",
        url: "/faq",
      },
      {
        title: "星鹿加速盒使用手册",
        url: "XinglooBox",
      },
      {
        title: "新闻资讯",
        url: "XinglooNews",
      },
      {
        title: "星鹿帮助文档",
        url: "XinglooDoc",
      },
    ],
  },
  {
    title: "合作",
    children: [
      {
        title: "代理商计划",
        url: "/agent-plan",
      },
      {
        title: "合作伙伴",
        url: "/partners",
      },
    ],
  },
];
const endStaticMenuList = [
  {
    title: "工具",
    children: [
      {
        title: "IP查询",
        url: "/tool",
      },
    ],
  },
  {
    title: "SD-WAN组网",
    children: [
      {
        title: "SD-WAN混合组网",
        url: "/sdwan-network",
      },
      {
        title: "SD-WAN应用加速",
        url: "/sdwan-app",
      },
    ],
  },
];

function FooterPanel(props) {
  const { history, device } = props;

  const [menuList, setMenuList] = useState([
    ...staticMenuList,
    {
      title: "热门地点",
      children: [
        {
          title: "更多地区",
          url: "/regions",
        },
      ],
    },
    ...endStaticMenuList,
  ]);
  const [showGzh, setShowGzh] = useState(false);
  const [locale, setLocale] = useState("zh_CN");

  const Copyright = () => {
    return (
      <div className="xl-footer__copyright">
        {AppDataByDomain.link ? (
          <a
            className="xl-footer__network-button"
            href={AppDataByDomain.link}
            target="_blank"
            rel="noreferrer"
          >
            {AppDataByDomain.appName}
          </a>
        ) : (
          <span>{AppDataByDomain.appName}</span>
        )}
        <a
          className="xl-footer__network-button"
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noreferrer"
        >
          {AppDataByDomain.publishNumber}
        </a>
        {AppDataByDomain.beianNumber ? (
          <a
            className="xl-footer__network-button"
            href={`https://beian.mps.gov.cn/#/query/webSearch?code=${AppDataByDomain.beianNumber}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="xl-footer__network-button-img"
              src={beianPic}
              alt="beian"
            />
            川公网安备 {AppDataByDomain.beianNumber}号
          </a>
        ) : null}
        {AppDataByDomain.businessLicense ? (
          <a
            className="xl-footer__network-button"
            href="https://tsm.miit.gov.cn/"
            target="_blank"
            rel="noreferrer"
          >
            增值电信业务经营许可证 {AppDataByDomain.businessLicense}
          </a>
        ) : null}
      </div>
    );
  };

  const getMenuList = useCallback(() => {
    const regionList = [];
    for (let i = 0; i < FooterHotRegions.length; i++) {
      if (typeof CountriesList[FooterHotRegions[i]] !== "undefined") {
        regionList.push({
          title: CountriesList[FooterHotRegions[i]].name[locale],
          url: `/regions/${CountriesList[
            FooterHotRegions[i]
          ].code.toLowerCase()}`,
        });
      }
    }
    if (device === "mobile") {
      setMenuList([
        {
          title: "解决方案",
          children: [
            {
              title: "海外直播",
              url: "/overseas-live",
            },
            {
              title: "跨境电商",
              url: "/e-commerce",
            },
            {
              title: "社媒运营",
              url: "/social-media",
            },
            {
              title: "账号矩阵",
              url: "/account-matrix",
            },
            {
              title: "跨境办公",
              url: "/office",
            },
            {
              title: "游戏",
              url: "/game",
            },
          ],
        },
        {
          title: "合作",
          children: [
            {
              title: "代理商计划",
              url: "/agent-plan",
            },
            {
              title: "合作伙伴",
              url: "/partners",
            },
          ],
        },
        ...endStaticMenuList,
        {
          title: "帮助中心",
          children: [
            {
              title: "FAQ",
              url: "/faq",
            },
            {
              title: "星鹿加速盒使用手册",
              url: "XinglooBox",
            },
            {
              title: "新闻资讯",
              url: "XinglooNews",
            },
            {
              title: "星鹿帮助文档",
              url: "XinglooDoc",
            },
          ],
        },
        {
          title: "热门地点",
          children: [
            ...regionList,
            {
              title: "更多地区",
              url: "/regions",
            },
          ],
        },
      ]);
    } else {
      setMenuList([
        ...staticMenuList,
        {
          title: "热门地点",
          children: [
            ...regionList,
            {
              title: "更多地区",
              url: "/regions",
            },
          ],
        },
        ...endStaticMenuList,
      ]);
    }
  }, [locale, device]);

  useEffect(() => {
    getMenuList();
  }, [device, getMenuList, locale]);

  const GotoPage = (prop) => {
    const { url, title } = prop;
    if (url === "XinglooBox") {
      return <span onClick={() => openXinglooBoxPath()}>{title}</span>;
    } else if (url === "XinglooNews") {
      return <span onClick={() => openXinglooNewsPath()}>{title}</span>;
    } else if (url === "XinglooDoc") {
      return <span onClick={() => openXinglooDocPath()}>{title}</span>;
    } else {
      return (
        <a href={url} className="item-title">
          {title}
        </a>
      );
    }
  };
  return (
    <Container>
      <div className="xl-footer__container">
        <div className="xl-footer__company-header">
          <div className="xl-footer__company-header-left">
            <img className="xl-footer__company-logo" src={logoPic} alt="pic" />
            {device !== "mobile" ? (
              <>
                <div className="xl-footer__company-desc">
                  <p>星鹿跨境电商加速盒</p>
                  <p>畅享全球极速连接，为您开拓无限可能</p>
                </div>
                {/* <SocialMedia /> */}
              </>
            ) : (
              <Copyright />
            )}
            {device !== "mobile" ? (
              <div className="xl-footer__company-title">联系我们</div>
            ) : null}
            {device !== "mobile" ? (
              <div className="xl-footer__company-contract">
                商务合作：{"   "}
                <span
                  className="xl-footer__company-email"
                  onClick={() => openMail("hudi@starsleap.com")}
                >
                  hudi@starsleap.com
                </span>
              </div>
            ) : null}
            {device !== "mobile" ? (
              <div className="xl-footer__company-contract">
                销售：{"   "}
                <span
                  className="xl-footer__company-email"
                  onClick={() => openMail("zhangheng1@starsleap.com")}
                >
                  zhangheng1@starsleap.com
                </span>
              </div>
            ) : null}
            {showGzh && (
              <img src={gzh_foot} className="gzh_foot" alt="pic" width={150} />
            )}
            {device !== "mobile" ? (
              <div className="xl-footer__company-pay">
                {/* <img
                  src={lx1Pic}
                  className="xl-footer__company-pay-item"
                  alt="pic"
                  onClick={() =>
                    window.open(
                      "https://mp.csdn.net/mp_blog/manage/article?spm=1011.2124.3001.5298"
                    )
                  }
                /> */}
                <img
                  src={lx2Pic}
                  className="xl-footer__company-pay-item"
                  alt="pic"
                  onMouseEnter={() => setShowGzh(true)}
                  onMouseLeave={() => setShowGzh(false)}
                />
                <img
                  src={lx3Pic}
                  className="xl-footer__company-pay-item"
                  alt="pic"
                  onClick={() => window.open("https://weibo.com/u/7974134299")}
                />
                <img
                  src={lx4Pic}
                  className="xl-footer__company-pay-item"
                  alt="pic"
                  onClick={() =>
                    window.open("https://www.zhihu.com/people/92-79-67-27")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="xl-footer__company-header-right">
            {menuList.map((item, index) => {
              return (
                <div className="nav-wrap" key={index}>
                  <div className="fontSize_16">{item.title}</div>
                  {item.children.map((cItem, cIndex) => {
                    return (
                      <div className="nav-item" key={cIndex}>
                        <GotoPage url={cItem.url} title={cItem.title} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="xl-footer__company-bottom">
          {/* { device !== 'mobile' ? null : <SocialMedia /> } */}
          {device !== "mobile" ? null : (
            <div className="xl-footer__company-pay">
              {/* <img
                src={lx1Pic}
                className="xl-footer__company-pay-item"
                alt="pic"
                onClick={() =>
                  window.open(
                    "https://mp.csdn.net/mp_blog/manage/article?spm=1011.2124.3001.5298"
                  )
                }
              /> */}
              {/* <img
                src={lx2Pic}
                className="xl-footer__company-pay-item"
                alt="pic"
                onClick={() =>
                  window.open(
                    "https://work.weixin.qq.com/ca/cawcde9ffc32c13ec4"
                  )
                }
              /> */}
              <img
                src={lx3Pic}
                className="xl-footer__company-pay-item"
                alt="pic"
                onClick={() => window.open("https://weibo.com/u/7974134299")}
              />
              <img
                src={lx4Pic}
                className="xl-footer__company-pay-item"
                alt="pic"
                onClick={() =>
                  window.open("https://www.zhihu.com/people/92-79-67-27")
                }
              />
            </div>
          )}
          <div className="xl-footer__company-pay">
            <img
              className="xl-footer__company-pay-icon"
              src={wepayPic}
              alt="pay"
            />
            <img
              className="xl-footer__company-pay-icon"
              src={alipayPic}
              alt="pay"
            />
          </div>
          {device !== "mobile" ? <Copyright /> : null}
        </div>
      </div>
    </Container>
  );
}

export default React.memo(FooterPanel);
