// app基本信息
const getAppData = () => {
    const AppHostName = window.location.hostname;
    if (AppHostName.includes("xingloo.net")) {
        return {
            appName: "四川星途时动科技有限公司",
            link: "https://www.starsleap.com",
            publishNumber: "蜀ICP备2024081007号",
            beianNumber: "51019002006756",
            businessLicense: "川B2-20240999 | B1-20242114"
        }
    }
    if (AppHostName.includes("xinglooremote.com")) {
        return {
            appName: "四川星途时动科技有限公司",
            link: "https://www.starsleap.com",
            publishNumber: "蜀ICP备2024081007号",
            beianNumber: "51019002006757",
            businessLicense: "川B2-20240999 | B1-20242114"
        }
    }
    if (AppHostName.includes("elreach.com")) {
        return {
            appName: "Elreach跨境电商加速盒",
            link: "",
            publishNumber: "蜀ICP备2024081007号",
            beianNumber: "51019002006844",
            businessLicense: ""
        }
    }
    if (AppHostName.includes("elreach.net")) {
        return {
            appName: "Elreach跨境电商加速盒",
            link: "",
            publishNumber: "蜀ICP备2024081007号",
            beianNumber: "51019002006842",
            businessLicense: ""
        }
    }
    // xingloo.com
    return {
        appName: "四川星途时动科技有限公司",
        link: "https://www.starsleap.com",
        publishNumber: "蜀ICP备2024081007号",
        beianNumber: "51019002006758",
        businessLicense: "川B2-20240999 | B1-20242114"
    }
}

export const AppDataByDomain = getAppData();

// 跳转星鹿用户中心
// let MainClientPath = "https://www.xingloo.com";
let MainClientPath = "https://user.xingloo.com";

if (window.location.hostname === "localhost" || window.location.hostname === "192.168.2.18") {
    MainClientPath = `${window.location.protocol}//${window.location.hostname}:8005`;
} else if (window.location.hostname === "192.168.2.233") {
    // 233
    MainClientPath = `${window.location.protocol}//${window.location.hostname}:8001`;
} else if (window.location.hostname === "pre.xingloo.net") {
    // 预发布
    MainClientPath = "https://pre-center.xingloo.net";
}

export const openMainClientPath = (path, name) => {
    const allPath = path ? `${MainClientPath}${path}` : MainClientPath;
    window.open(allPath, name || '_blank');
}

// 跳转IPFLY
const IpflyPath = "https://ipfly.net";
export const openIpflyPath = (path, name) => {
    const allPath = path ? `${IpflyPath}${path}` : IpflyPath;
    window.open(allPath, name || '_blank');
}

// 跳转星鹿加速盒使用手册
const XinglooBoxPath = `${window.location.origin}/product`;
export const openXinglooBoxPath = (path, name) => {
    const allPath = path ? `${XinglooBoxPath}${path}` : XinglooBoxPath;
    window.open(allPath, name || '_blank');
}

// 跳转星鹿资讯页面
// let XinglooNewsPath = "https://news.xingloo.com/doc/";
let XinglooNewsPath = "https://www.xingloo.com/news";
// if (window.location.hostname === "localhost" || window.location.hostname === "192.168.2.18" || window.location.hostname === "192.168.2.233") {
//     XinglooNewsPath = "http://192.168.2.233:8005/doc/";
// }
export const openXinglooNewsPath = (path, name) => {
    const allPath = path ? `${XinglooNewsPath}${path}` : XinglooNewsPath;
    window.open(allPath, name || '_blank');
}

// 跳转星鹿文档页面
// let XinglooDocPath = "https://www.xingloo.com/doc/";
let XinglooDocPath = "https://doc.xingloo.com/doc/";
if (window.location.hostname === "localhost" || window.location.hostname === "192.168.2.18" || window.location.hostname === "192.168.2.233") {
    XinglooDocPath = "http://192.168.2.233:8004/doc/";
}
export const openXinglooDocPath = (path, name) => {
    const allPath = path ? `${XinglooDocPath}${path}` : XinglooDocPath;
    window.open(allPath, name || '_blank');
}

// FAQ类型
function mapToList(mData) {
    const list = [];
    mData.forEach(item => {
        list.push(item);
    })
    return list;
}

export const FaqAllType = '0';
export const FaqGeneralType = '1';
export const FaqBoxType = '2';
export const FaqSdwanType = '3';
export const FaqStaticIPType = '4';

const faqTypeMap = new Map();
faqTypeMap.set(FaqAllType, {
    label: "所有问题",
    value: FaqAllType
})
faqTypeMap.set(FaqGeneralType, {
    label: "常见问题",
    value: FaqGeneralType
})
faqTypeMap.set(FaqBoxType, {
    label: "星鹿跨境电商加速盒",
    value: FaqBoxType
})
faqTypeMap.set(FaqSdwanType, {
    label: "SD-WAN解决方案",
    value: FaqSdwanType
})
// faqTypeMap.set(FaqStaticIPType, {
//     label: "静态住宅IP",
//     value: FaqStaticIPType
// })
export const FaqTypeMap = faqTypeMap;
export const FaqTypeList = mapToList(faqTypeMap);

// 联系客服
export const OpenChat = () => {
    if (window._AIHECONG) {
        window._AIHECONG('showChat');
    }
}
// 打开邮箱
export const openMail = (mail) => {
    window.open(`mailto:${mail}`, '_blank');
}

// 合作伙伴分类类型
export const PartnerTypeOptions = {
    '0': {
        label: '推荐', // 推荐
        value: '0'
    },
    '1': {
        label: '通信运营商', // 通信运营商
        value: '1'
    },
    '2': {
        label: '跨境工具', // 跨境工具
        value: '2'
    },
    '3': {
        label: '跨境金融', // 跨境工具
        value: '3'
    },
    '4': {
        label: '跨境服务', // 跨境工具
        value: '4'
    },
    '5': {
        label: '其他', // 其他
        value: '5'
    }
}