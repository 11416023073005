import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Solution1Pic } from '@/assets/svg/nav/solution1.svg';
import { ReactComponent as Solution2Pic } from '@/assets/svg/nav/solution2.svg';
import { ReactComponent as Solution3Pic } from '@/assets/svg/nav/solution3.svg';
import { ReactComponent as Solution4Pic } from '@/assets/svg/nav/solution4.svg';
import { ReactComponent as Solution5Pic } from '@/assets/svg/nav/solution5.svg';
import { ReactComponent as Solution6Pic } from '@/assets/svg/nav/solution6.svg';

const Container = styled.div`
    position: absolute;
    left: ${props => `${-238 + (props.index * 128 + 66)}px`};
    top: 51px;
    width: 798px;
    min-height: 259px;
    background: #FFFFFF;
    color: #0C1939;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    border-radius: 12px 12px 12px 12px;
    z-index: 2;
    .main-wrap {
        padding: 20px 28px;
        .title {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .nav-list-wrap {
            display: flex;
            align-items: center;
            .nav-item {
                color: #0c1939;
                width: 50%;
                padding: 10px 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background-color: #F2F8FE;
                    border-radius: 4px
                }
                .nav-icon {
                    flex-shrink: 0;
                    width: 44px;
                    height: 44px;
                    margin-right: 16px;
                }
                .nav-cont {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .nav-title {
                        font-family: 'SourceHanSansCN-Medium';
                    }
                    .nav-desc {
                        font-size: 12px;
                        color: #0B1B32;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
    &.sub-nav-picker-inline {
        position: static;
        width: 100%;
        min-height: auto;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        .main-wrap {
            padding: 16px 0;
            border-top: 1px solid rgba(0,0,0,0.05);
            .title {
                color: #989FAA;
                font-family: 'SourceHanSansCN-Medium';
                margin-bottom: 0;
            }
            .nav-list-wrap {
                flex-wrap: wrap;
                .nav-item {
                    width: 100%;
                    margin: 16px 0 0 0;
                    padding: 0;
                    .nav-icon {
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
                    }
                    .nav-cont {
                        .nav-title {
                            font-size: 12px;
                        }
                        .nav-desc {
                            display: none;
                        }
                    }
                }
            }
        }
    }
`

function Comp(props) {
    const { history, currentIndex, inline, showSubNav } = props;
    return <Container className={`${
        inline ? "sub-nav-picker sub-nav-picker-inline" : "sub-nav-picker"
      } ${showSubNav ? "show-nav" : "hidden-nav"}`} index={currentIndex}>
        <div className='main-wrap'>
            <div className='title'>解决方案</div>
            <div className='nav-list-wrap'>
                <a className='nav-item' href='/overseas-live'>
                    <Solution1Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>海外直播</div>
                        <div className='nav-desc'>攻克海外直播网络延迟、IP合规难题，开启流畅直播</div>
                    </div>
                </a>
                <a className='nav-item' href='/e-commerce'>
                    <Solution2Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>跨境电商</div>
                        <div className='nav-desc'>化解跨境电商网络卡顿、IP壁垒，畅通全球贸易之路</div>
                    </div>
                </a>
            </div>
            <div className='nav-list-wrap'>
                <a className='nav-item' href='/social-media'>
                    <Solution3Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>社媒运营</div>
                        <div className='nav-desc'>突破社媒运营IP封锁与关联风险，助力品牌社交腾飞</div>
                    </div>
                </a>
                <a className='nav-item' href='/account-matrix'>
                    <Solution4Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>账号矩阵</div>
                        <div className='nav-desc'>梳理账号资源，规避关联风险，稳固矩阵运营根基</div>
                    </div>
                </a>
            </div>
            <div className='nav-list-wrap'>
                <a className='nav-item' href='/office'>
                    <Solution5Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>跨境办公</div>
                        <div className='nav-desc'>扫除跨境办公网络障碍，提升跨国协作效能</div>
                    </div>
                </a>
                <a className='nav-item' href='/game'>
                    <Solution6Pic className='nav-icon' />
                    <div className='nav-cont'>
                        <div className='nav-title'>游戏</div>
                        <div className='nav-desc'>解决游戏网络延迟与IP封禁难题，重塑畅爽游戏体验</div>
                    </div>
                </a>
            </div>
        </div>
    </Container>
}

export default React.memo(Comp);